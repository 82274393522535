import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

import { Box, Flex } from 'components/box/Box';

import BorrowerAdminPanel from './BorrowerAdminPanel';

const DataSection = ({ data }) => (
  <Section key="userHighInfo">
    {data.map((row, i) => {
      return (
        <GridRow key={i}>
          {row.map(item => {
            return (
              <Grid key={item}>
                <Label weight="regular" size="medium">
                  {item.label}:
                </Label>
                <Text weight="bold" size="medium">
                  {item.value}
                </Text>
              </Grid>
            );
          })}
        </GridRow>
      );
    })}
  </Section>
);

const BorrowerHighInfo = ({ data, borrower }) => {
  const { profile } = borrower;
  return (
    <>
      <Container as="section">
        <Flex alignItems="center">
          <Header>
            {profile.firstName} {profile.lastName}
          </Header>
          <BorrowerAdminPanel borrower={borrower} />
        </Flex>
        {data.map(section => (
          <DataSection
            key={section.title}
            title={section.title}
            data={section.data}
            colWidth={section.colWidth || '1fr'}
          />
        ))}
      </Container>
    </>
  );
};

const Container = styled(Box)`
  background-color: #ffffff;
  margin-left: -48px;
  margin-right: -48px;
  padding: 48px;
  margin-top: 32px;
`;

const Section = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const GridRow = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 2fr 1fr 1fr;

  margin-bottom: 8px;
`;
const Grid = styled.div`
  display: flex;
  flex-direction: row;
  height: 26px;
`;
const Label = styled(Text)`
  margin-right: 2px;
`;

BorrowerHighInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          }).isRequired,
        ),
      ).isRequired,
      colWidth: PropTypes.string,
    }).isRequired,
  ),
};

BorrowerHighInfo.defaultProps = {
  colWidth: 'auto',
};

export default BorrowerHighInfo;
