import { isEmpty, omitBy } from 'lodash';
import { useCallback, useState } from 'react';

export const useStateFromUrl = initialState => {
  const queryParams = new URLSearchParams(window.location.search);
  const initState = { ...initialState };
  queryParams.forEach((value, key) => {
    initState[key] = value;
  });
  const [state, setState] = useState(initState);

  const updateState = useCallback(
    (name, value) => {
      const newState = {
        ...state,
        [name]: value,
      };
      setState(newState);

      const appliedState = omitBy(newState, isEmpty);
      const urlParams = Object.entries(appliedState)
        .map(([key, val]) => `${key}=${val}`)
        .join('&');

      // add to history
      window.history.pushState(
        '',
        '',
        `${window.location.pathname}${
          isEmpty(urlParams) ? '' : '?'
        }${urlParams}`,
      );
    },
    [state, setState],
  );

  return [state, updateState];
};
