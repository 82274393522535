/*
 * This file have 2 Base Components that can use as skeleton of every other component
 * Todo: find better name for this file
 * */

import propTypes from '@styled-system/prop-types';
import styled from 'styled-components';
import {
  space,
  color,
  layout,
  flexbox,
  border,
  position,
  compose,
} from 'styled-system';

export const Box = styled.div(
  compose(space, color, layout, flexbox, border, position),
);

export const Flex = styled(Box)`
  display: flex;
`;

Box.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.flexbox,
  ...propTypes.border,
  ...propTypes.position,
};
