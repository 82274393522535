export const welcomes = [
  {
    text: 'welcome',
  },
  {
    text: 'welkom',
  },
  {
    text: 'mirë se vini',
  },
  {
    text: 'welkomma',
  },
  {
    text: 'na gasana',
  },
  {
    text: 'bel bonjou',
  },
  {
    text: 'مرحبا ',
  },
  {
    text: 'أهلا وسهلا ',
  },
  {
    text: 'bari galoust',
  },
  {
    text: 'xos gelmissiniz',
  },
  {
    text: 'i bisimila',
  },
  {
    text: 'akwaba',
  },
  {
    text: 'ongi etorri',
  },
  {
    text: 'Шчыра запрашаем',
  },
  {
    text: 'swagata',
  },
  {
    text: 'amrehba sisswène',
  },
  {
    text: 'ani kié',
  },
  {
    text: 'dobro došli',
  },
  {
    text: 'degemer mad',
  },
  {
    text: 'добре дошъл',
  },
  {
    text: 'kyo tzo pa eit',
  },
  {
    text: 'tirow',
  },
  {
    text: 'benvinguts',
  },
  {
    text: 'hafa adai',
  },
  {
    text: 'marsha vogiyla',
  },
  {
    text: 'ulihelisdi',
  },
  {
    text: '欢迎你来 ',
  },
  {
    text: 'bonavinuta',
  },
  {
    text: 'dobrodošli',
  },
  {
    text: 'vítejte',
  },
  {
    text: 'velkommen',
  },
  {
    text: 'pô la bwam',
  },
  {
    text: 'welkom',
  },

  {
    text: 'bonvenon',
  },
  {
    text: 'tere tulemast',
  },
  {
    text: 'woezon',
  },
  {
    text: 'vælkomin',
  },
  {
    text: 'tervetuloa',
  },
  {
    text: 'welkom',
  },
  {
    text: 'bienvenue',
  },
  {
    text: 'wolkom',
  },
  {
    text: 'binvignut',
  },
  {
    text: 'awaa waa atuu',
  },
  {
    text: 'benvido',
  },
  {
    text: 'bin la vnu',
  },
  {
    text: 'mobrdzandit',
  },
  {
    text: 'herzlich willkommen',
  },
  {
    text: 'waila andanema ',
  },
  {
    text: 'Καλώς ήλθατε',
  },
  {
    text: 'eguahé porá',
  },
  {
    text: 'mikouabô',
  },
  {
    text: 'bienvéni',
  },
  {
    text: 'e komo mai',
  },
  {
    text: 'baroukh haba ',
  },
  {
    text: 'swaagat ',
  },
  {
    text: 'tos txais',
  },
  {
    text: 'üdvözlet',
  },
  {
    text: 'velkomin',
  },
  {
    text: 'nnoo',
  },
  {
    text: 'selamat datang',
  },
  {
    text: 'fáilte',
  },
  {
    text: 'benvenuto',
  },
  {
    text: 'yôkoso',
  },
  {
    text: 'laaslama',
  },
  {
    text: 'chum reap suor',
  },
  {
    text: 'nkuîzulu yambote',
  },
  {
    text: 'murakaza neza',
  },
  {
    text: '환영합니다',
  },
  {
    text: 'nodé',
  },
  {
    text: 'bi xer hati',
  },
  {
    text: 'gnindi ton hap',
  },
  {
    text: 'nkuîzulu yambote',
  },
  {
    text: 'gratus mihi venis',
  },
  {
    text: 'laipni lūdzam',
  },
  {
    text: 'benvegnûo',
  },
  {
    text: 'boyeyi bolamu',
  },
  {
    text: 'sveiki atvykę',
  },
  {
    text: 'welkum',
  },
  {
    text: 'wëllkom',
  },
  {
    text: 'добредојде',
  },
  {
    text: 'tonga soa',
  },
  {
    text: 'selamat datang',
  },
  {
    text: 'swagatham',
  },
  {
    text: 'merħba',
  },
  {
    text: 'haere mai',
  },
  {
    text: 'kakwa o',
  },
  {
    text: 'miawezon',
  },
  {
    text: 'Тавтай морилогтун',
  },
  {
    text: 'ne y waoongo',
  },
  {
    text: 'namaste',
  },
  {
    text: 'byivenun',
  },
  {
    text: 'bianvnu',
  },
  {
    text: 'beinvnu',
  },
  {
    text: 'velkommen',
  },
  {
    text: 'benvenguts',
  },
  {
    text: 'velkom',
  },
  {
    text: 'Табуафси',
  },
  {
    text: 'bon bini',
  },
  {
    text: 'خوش آمدید یا',
  },
  {
    text: 'witaj',
  },
  {
    text: 'bem-vindo',
  },
  {
    text: 'mishto-avilian tú',
  },
  {
    text: 'bine ai venit ',
  },
  {
    text: 'добро пожаловать ',
  },
  {
    text: 'afio mai',
  },
  {
    text: 'beni benìu',
  },
  {
    text: 'fàilte',
  },
  {
    text: 'dobrodošli',
  },
  {
    text: 'karibu',
  },
  {
    text: 'wauya',
  },
  {
    text: 'bhali karay aaya',
  },
  {
    text: 'aayuboovan',
  },
  {
    text: 'vitajte',
  },
  {
    text: 'dobrodošel ',
  },
  {
    text: 'zupinje z te videtite',
  },
  {
    text: 'soo dhawaw',
  },
  {
    text: 'bienvenido',
  },
  {
    text: 'wilujeng sumping',
  },
  {
    text: 'karibu',
  },
  {
    text: 'välkommen',
  },
  {
    text: 'härzliche wöikomme',
  },
  {
    text: 'maligayang pagdating',
  },
  {
    text: 'maeva / manava',
  },
  {
    text: 'nal-varravu',
  },
  {
    text: 'rahim itegez',
  },
  {
    text: 'swagatham',
  },
  {
    text: 'ยินดีต้อนรับ (yindii ton rap)',
  },
  {
    text: 'tashi delek',
  },
  {
    text: 'malo e lelei',
  },
  {
    text: 'difika dilenga',
  },
  {
    text: 'hoş geldiniz',
  },
  {
    text: 'gazhasa oetiśkom',
  },
  {
    text: 'Ласкаво просимо ',
  },
  {
    text: ' khush amdeed',
  },
  {
    text: 'hush kelibsiz',
  },
  {
    text: 'chào mừng ',
  },
  {
    text: 'bénvnou',
  },
  {
    text: 'croeso',
  },
  {
    text: 'dalal ak diam',
  },
  {
    text: 'ékouabô / ékabô',
  },
];
