import React from 'react';
import styled from 'styled-components';

import { Button, FlexColumn, Text } from '@summer/ui-components';

import { Flex } from 'components/box/Box';

const Error = ({ error }) => {
  const isVpn = new URLSearchParams(window.location.search).get('isVpn');

  // Note: this error is only passed by useSelf and will not show other route errors
  const hasErrorMessage = !!error?.message;

  return (
    <Flex width={1} height="100%" justifyContent="center" alignItems="center">
      <Container>
        {isVpn ? (
          <ErrorVPN hasErrorMessage={hasErrorMessage} />
        ) : (
          <ErrorDefault />
        )}
        <Button
          onClick={() => {
            window.location = '/';
          }}
          fakeDisabled={isVpn && hasErrorMessage}
        >
          Back to the app
        </Button>
      </Container>
    </Flex>
  );
};

const ErrorVPN = ({ hasErrorMessage }) => (
  <>
    <TextContainer>
      {hasErrorMessage && (
        <>
          <Text>Please make sure you are connected to the VPN</Text>
        </>
      )}
      {!hasErrorMessage && (
        <Text>Reconnected to VPN! Try going back to the app</Text>
      )}
    </TextContainer>
  </>
);

const ErrorDefault = () => (
  <>
    <TextContainer>
      <Text>We Had A Problem Processing Your Latest Request</Text>
      <Text>Please try again or contact engineering</Text>
    </TextContainer>
  </>
);

const Container = styled(FlexColumn)`
  justifycontent: center;
  text-align: center;
`;
const TextContainer = styled.div`
  & > * {
    margin-bottom: 24px;
  }
`;

export default Error;
