import React from 'react';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { Text, Icon, ModalPopup } from '@summer/ui-components';

import { ReactComponent as RightArrow } from 'assets/icons/right-arrow.svg';
import { Flex } from 'components/box/Box';
import Loading from 'components/placeholders/Loading';
import { useSummerHistory } from 'hooks/useSummerHistory';
import { useForm } from 'state/server/forms';
import { getThemeColor } from 'utils/theme-helpers';
import FormData from 'views/Form/FormData';
import FormHeader from 'views/Form/FormHeader';
import FormMetadata from 'views/Form/FormMetadata';
import FormNotes from 'views/Form/FormNotes';

const FormBody = ({ form, isModal, isLoading }) => {
  return (
    <FormBox width={1} min-height={463} bg="white" mt={32}>
      <RightSection flex={1} p={32} flexDirection="column" overflow="hidden">
        <FormHeader form={form} isLoading={isLoading} />
        <FormData form={form} />
        <FormNotes formId={form?.id} formNotes={form?.metadata?.notes} />
      </RightSection>

      <LeftSection bg="blueGrey" width={299} p={32}>
        <FormMetadata
          formId={form?.id}
          formType={form?.formType}
          formMetadata={form?.metadata}
          formStatus={form?.status}
          formFaxSubmission={form?.faxSubmission}
          formIsConsolidation={form?.form.isConsolidation ?? null}
          formServicersToSendIDR={form?.form.servicersToSendIDR ?? null}
          fromOverlay={isModal}
        />
      </LeftSection>
    </FormBox>
  );
};

const FormView = props => {
  const { togglePopup, isPopupOpen, isModal } = props;
  const theme = useTheme();
  const { formId } = useParams();
  const history = useSummerHistory();
  const { form, isLoading } = useForm({ formId: formId ?? props.formId });

  if (isLoading) {
    return <Loading />;
  }

  return !isModal ? (
    <>
      <Click
        height={32}
        alignItems="center"
        onClick={() => history.goBack('/forms')}
      >
        <Icon
          SvgComponent={RightArrow}
          width={14}
          fill={getThemeColor('azure')({ theme })}
        />
        <Text color="summer" bold>
          Back to Forms Received
        </Text>
      </Click>
      <FormBody form={form} isLoading={isLoading} isModal={isModal} />
    </>
  ) : (
    <ModalPopup
      {...props}
      togglePopup={togglePopup}
      isOpen={isPopupOpen}
      kind="Update Status"
      fullHeight="small"
      width={1500}
      bodyPadding="72px 32px 40px"
      closeSize={20}
      component={() => (
        <FormBody form={form} isLoading={isLoading} isModal={isModal} />
      )}
    />
  );
};

const Click = styled(Flex)`
  cursor: pointer;
  & > * {
    margin-right: 8px;
  }
`;

const LeftSection = styled(Flex)``;

const RightSection = styled(Flex)`
  height: 100%;
  border-radius: 6px;
  & > * {
    margin-bottom: 16px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;
const FormBox = styled(Flex)`
  border-radius: 6px;
  box-shadow: 0 2px 20px 0 #e5e8ee;
  border: solid 1px ${getThemeColor('lighterGrey')};
`;

export default FormView;
