import { isString, isObject } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

export const useSummerHistory = () => {
  const history = useHistory();
  const location = useLocation();

  const push = (pathOrLocation, state = {}) => {
    if (isString(pathOrLocation)) {
      const newState = { ...state, adminApp: true };
      history.push(pathOrLocation, newState);
      return;
    }

    if (isObject(pathOrLocation)) {
      const location = {
        ...pathOrLocation,
        state: {
          ...pathOrLocation.state,
          adminApp: true,
        },
      };
      history.push(location);
    }
  };

  const goBack = defaultPath => {
    if (!location?.state?.adminApp) {
      history.push(defaultPath);
      return;
    }
    history.goBack();
  };

  return { ...history, push, goBack };
};
