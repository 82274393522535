import { parseISO, format } from 'date-fns';
import { isNil, map, filter } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { SelectInput, Text } from '@summer/ui-components';

import { Box } from 'components/box/Box';
import { useAdminUsers, useStatuses } from 'state/server/constants';
import { useMutateForm } from 'state/server/forms';
import { useSelf } from 'state/server/self';
import { isIdr, isPslf } from 'utils/constants';
import { arrayToOptions } from 'utils/general';

import AutoFax from './AutoFax/AutoFax';
import ChangeStatusModal from './StatusModal';

const FormMetadata = ({
  formId,
  formType,
  formMetadata,
  formStatus,
  formFaxSubmission,
  formIsConsolidation,
  formServicersToSendIDR,
  fromOverlay,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const statuses = useStatuses();
  const owners = useAdminUsers();
  const { mutateAsync } = useMutateForm({ fromOverlay });
  const queryClient = useQueryClient();
  const { self } = useSelf();

  const statusesForOptions = useMemo(() => {
    const filterStatuses = filter(statuses, item => {
      if (item?.formType === 'both') {
        return true;
      }

      if (isPslf(item?.formType) && isPslf(formType)) {
        return true;
      }

      if (isIdr(item?.formType) && isIdr(formType)) {
        return true;
      }

      return false;
    });
    return arrayToOptions(map(filterStatuses, 'status'));
  }, [formType, statuses]);

  const handleInputChange = useCallback(
    async (name, value) => {
      if (
        name === 'status' &&
        !isPopupVisible &&
        value === 'Submitted to Servicer-Standard'
      ) {
        setIsPopupVisible(true);
      } else {
        setIsPopupVisible(false);
        await mutateAsync(
          {
            formId,
            fromOverlay,
            payload: {
              [name]: value,
            },
          },
          {
            onSuccess: () => {
              if (fromOverlay) {
                queryClient.invalidateQueries(['user']);
              }
            },
          },
        );
      }
    },
    [isPopupVisible, mutateAsync, formId, fromOverlay, queryClient],
  );
  const handleModalConfirm = useCallback(() => {
    handleInputChange('status', 'Submitted to Servicer-Standard');
  }, [handleInputChange]);

  const statusDate = isNil(formMetadata?.statusDate)
    ? '-'
    : format(parseISO(formMetadata?.statusDate), 'MMM d, yyyy');

  const enableAdminFax = self?.flags?.enableAdminFax;
  const enableAdminPslfFax = self?.flags?.enableAdminPslfFax;

  const showAdminFax =
    enableAdminFax && (isPslf(formType) ? enableAdminPslfFax : true);

  return (
    <Container width={1}>
      <Box>
        <SelectInput
          notFormik
          isClearable
          label="Status"
          name="status"
          placeholder="No Status"
          options={statusesForOptions}
          onChange={handleInputChange}
          value={formMetadata?.status}
          onBlur={() => {}}
        />
        <StaticContainer>
          <Text bold>Status Date</Text>
          <Text weight="medium">{statusDate}</Text>
        </StaticContainer>
      </Box>
      {showAdminFax && (
        <StaticContainer>
          <AutoFax
            formId={formId}
            formType={formType}
            formStatus={formStatus}
            faxSubmission={formFaxSubmission}
            formIsConsolidation={formIsConsolidation}
            formServicersToSendIDR={formServicersToSendIDR}
          />
        </StaticContainer>
      )}
      {isPslf(formType) && (
        <SelectInput
          notFormik
          isClearable
          label="Form in Zendesk?"
          name="formInZendesk"
          placeholder="Select Option"
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          onChange={handleInputChange}
          value={formMetadata?.formInZendesk}
          onBlur={() => {}}
        />
      )}
      <SelectInput
        notFormik
        isClearable
        label="Owner"
        name="owner"
        placeholder="No Owner"
        options={
          owners?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })) || []
        }
        value={formMetadata?.owner?.uuid}
        onChange={handleInputChange}
        onBlur={() => {}}
      />
      {isIdr(formType) && (
        <StaticContainer>
          <Text bold>Product Status</Text>
          <Text weight="medium">{formStatus}</Text>
        </StaticContainer>
      )}
      {isPopupVisible && (
        <ChangeStatusModal
          isPopupOpen={isPopupVisible}
          togglePopup={() => setIsPopupVisible(false)}
          onConfirm={handleModalConfirm}
        />
      )}
    </Container>
  );
};

const Container = styled(Box)`
  margin: auto;

  & > * {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const StaticContainer = styled(Box)`
  & > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default FormMetadata;
