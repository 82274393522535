import React from 'react';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { Text, Icon } from '@summer/ui-components';

import { ReactComponent as RightArrow } from 'assets/icons/right-arrow.svg';
import { Box, Flex } from 'components/box/Box';
import { NotePad } from 'components/notes';
import Loading from 'components/placeholders/Loading';
import { useSummerHistory } from 'hooks/useSummerHistory';
import { useBorrower } from 'state/server/borrowers';
import { useSelf } from 'state/server/self';
import { getThemeColor } from 'utils/theme-helpers';
import BorrowerData from 'views/Borrower/BorrowerData';

const BorrowerView = () => {
  const theme = useTheme();
  const history = useSummerHistory();
  const { self: adminUser } = useSelf();

  const { borrowerId } = useParams();

  const { data: borrower, isLoading } = useBorrower(borrowerId);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Wrapper>
      <Container>
        <Click
          height={32}
          alignItems="center"
          onClick={() => history.goBack('/')}
        >
          <Icon
            SvgComponent={RightArrow}
            width={14}
            fill={getThemeColor('azure')({ theme })}
          />
          <Text color="summer" bold>
            Back to Borrowers Search
          </Text>
        </Click>
      </Container>
      {borrower !== undefined && <BorrowerData borrower={borrower} />}
      <NotePad borrowerId={borrowerId} adminUser={adminUser} />
    </Wrapper>
  );
};

export default BorrowerView;

const Container = styled(Box)`
  margin: auto;

  & > * {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const Click = styled(Flex)`
  cursor: pointer;
  & > * {
    margin-right: 8px;
  }
`;
const Wrapper = styled(Box)`
  margin-bottom: 40px;
`;
