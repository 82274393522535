/* eslint-disable react/display-name */
import { get } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Cell, Header } from '@summer/ui-components';

import { Box } from 'components/box/Box';
import DateCell from 'components/cells/DateCell';
import CardTable from 'components/table/SortableCardTable';
import { calcDuration, signatureStatus } from 'utils/forms';
import FormView from 'views/Form/FormView';

const BorrowerPSLFFormSummaryTable = ({ forms }) => {
  const containerRef = useRef();
  const [formId, setFormId] = useState(null);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Date Received',
        accessor: 'signedAt',
        Cell: ({ cell }) => (
          <DateCell size="small" value={cell.value} formatString="MMM d yyyy" />
        ),
      },
      { Header: 'Employer', accessor: 'form.employerName' },
      { Header: 'Employer Type', accessor: 'form.employerType' },
      {
        Header: 'Duration',
        accessor: 'firstName',
        Cell: ({ row }) => (
          <Cell
            type="string"
            size="small"
            value={calcDuration(
              row.original.form.employerStartDate,
              row.original.form.employerEndDate,
            )}
          />
        ),
      },

      {
        Header: 'Hours/wk',
        accessor: 'form.employerHoursPerWeek',
      },
      {
        Header: 'Hr Contact',
        Cell: ({ row }) => (
          <Cell
            type="string"
            size="small"
            value={`${row.original.form.hrFirstName}
        
              ${row.original.form.hrLastName}`}
          />
        ),
      },
      {
        Header: 'Hr Contact Email',
        accessor: 'form.hrEmail',
      },
      {
        Header: 'Signature Status',
        accessor: 'signRequests',
        Cell: ({ cell }) => (
          <Cell
            type="string"
            size="small"
            value={signatureStatus(cell.value)}
          />
        ),
      },
      {
        Header: 'Status Date',
        accessor: 'metadata.statusDate',
        Cell: ({ cell }) => (
          <DateCell size="small" value={cell.value} formatString="MMM d yyyy" />
        ),
      },
    ];
  }, []);

  return (
    <Box mt={32} mb={32} ref={containerRef}>
      <Header size="large" weight="bold">
        PSLF Application Forms
      </Header>
      <TableContainer borderColor="blueGrey" mt={24}>
        <CardTable
          columns={columns}
          data={forms}
          colDefaultWidth={80}
          limitColWidth={true}
          virtualizedOptions={{
            height: 94 * forms.length + 1,
          }}
          align="left"
          style={{
            left: 0,
            overflowX: 'visible',
            overflowY: 'auto',
            flex: 0,
          }}
          getCustomRowProps={(props, options) => {
            const { row } = options;
            const formId = get(row, 'original.id');
            // combine row props With our custom row props
            const style = {
              ...props.style,
              left: 0,
              width: '100%',
              paddingLeft: '8px',
              paddingRight: '8px',
            };
            return {
              ...props,
              style,
              onClick: () => {
                setFormId(formId);
              },
            };
          }}
        />
      </TableContainer>
      {formId && (
        <FormView
          formId={formId}
          isPopupOpen={!!formId}
          togglePopup={() => setFormId(null)}
          isModal={true}
        />
      )}
    </Box>
  );
};
const TableContainer = styled(Box)``;

export default BorrowerPSLFFormSummaryTable;
