import { format, parseISO } from 'date-fns';
import { isNil, capitalize, sum, isEmpty, some, sortBy } from 'lodash';
import React from 'react';

import { USStateDisplayNameMapping } from '@simplifidev/shared/dist/constants/states';

import { Text } from '@summer/ui-components';

import {
  LOAN_TYPE_FSA,
  LOAN_TYPE_PLAID_FEDERAL,
  isPslf,
  isIdr,
} from 'utils/constants';
import {
  dollars,
  yesOrNo,
  rateParser,
  avgInterestRate,
  isCaresActEligible,
  formatPhone,
} from 'utils/general';

export const borrowerHighInfoData = ({ borrower }) => {
  const formatDate = date =>
    !isNil(date) ? format(parseISO(date), 'LL/dd/yyyy, hh:mm a') : '-';
  const formatDateOnly = date =>
    !isNil(date) ? format(date, 'yyyy-MM-dd') : '-';
  const isCompletedIDR = forms =>
    forms?.some(
      form =>
        isIdr(form.formType) &&
        (form.status === 'signed' || form.status === 'processed'),
    )
      ? 'Yes'
      : 'No';

  const isCompletdPSLF = forms =>
    forms?.some(
      form =>
        isPslf(form.formType) &&
        (form.status === 'signed' || form.status === 'processed'),
    )
      ? 'Yes'
      : 'No';

  const isConsolidation = forms =>
    forms?.some(form => form.form.isConsolidation) ? 'Yes' : 'No';

  const paymentDetailsSaved = stripeMembership =>
    stripeMembership?.status === 'payment_saved' ? 'Yes' : 'No';

  const verifiedLink = () => {
    return (
      <Text
        style={{ cursor: 'pointer' }}
        color="summer"
        bold
        text-decoration="underline"
        onClick={async () => {
          await navigator.clipboard.writeText(
            `https://app.meetsummer.org/user/verify/${borrower?.verificationToken}`,
          );
        }}
      >
        Copy
      </Text>
    );
  };

  return [
    {
      data: [
        [
          { label: 'Borrower ID', value: borrower.id },
          { label: 'Partner', value: borrower.partnerName },
          { label: 'Completed IDR', value: isCompletedIDR(borrower.forms) },
        ],
        [
          { label: 'Email', value: borrower.email },
          {
            label: 'Synced FSA',
            value: !isEmpty(borrower.loans) ? 'Yes' : 'No',
          },
          {
            label: 'Synced Plaid',
            value: !isEmpty(borrower.plaidLoans) ? 'Yes' : 'No',
          },
        ],
        [
          { label: 'Last Log In', value: formatDate(borrower.lastLogin) },
          {
            label: 'Email verified',
            value: isNil(borrower.isVerified)
              ? '-'
              : yesOrNo(borrower.isVerified),
          },
          {
            label: 'RM Enabled',
            value: yesOrNo(borrower.isUserRMEligible),
          },
        ],
        [
          { label: 'Account created', value: formatDate(borrower.createdAt) },
          { label: 'Completed PSLF', value: isCompletdPSLF(borrower.forms) },
          {
            label: 'Opted out of RM',
            value: formatDate(borrower.retirementMatchOptedOutAt),
          },
        ],
        [
          { label: 'Social SSO', value: yesOrNo(borrower.socialSSO) },
          {
            label: 'Payment details saved',
            value: paymentDetailsSaved(borrower.stripeMembership),
          },
          {
            label: 'RM Start Date',
            value: borrower.retirementMatchStartedAt
              ? formatDateOnly(new Date(borrower.retirementMatchStartedAt))
              : '-',
          },
        ],
        [
          { label: 'Partner SSO', value: yesOrNo(borrower.partnerSSO) },
          { label: 'Verification Link', value: verifiedLink() },
          {
            label: 'Consented to RM',
            value: formatDate(borrower.retirementMatchConsentedAt),
          },
        ],
        [{ label: 'Consolidation', value: isConsolidation(borrower.forms) }],
      ],
    },
  ];
};

export const borrowerOnboardingInfoData = ({ borrower }) => {
  return [
    {
      title: 'Borrower Info',
      data: [
        [
          {
            label: 'Education',
            value: capitalize(borrower.profile.gradStatus),
          },
          {
            label: 'Expected Household gross Income',
            value: dollars(borrower.salary),
          },
          {
            label: 'State',
            value: USStateDisplayNameMapping[borrower.profile.state],
          },
          {
            label: 'Credit Score',
            value: isNil(borrower.creditScore) ? '-' : borrower.creditScore,
          },
          {
            label: 'Employer',
            value: !isNil(borrower.profile?.currentEmployer)
              ? borrower.profile.currentEmployer
              : '-',
          },
        ],
        [
          {
            label: 'Trouble With Payments',
            value: isNil(borrower.troubleWithPayments)
              ? '-'
              : capitalize(borrower.troubleWithPayments),
          },
          {
            label: 'Family Size',
            value: !isNil(borrower.familyCount) ? borrower.familyCount : '-',
          },
          {
            label: 'Non Profit/public sector?',
            value: !isNil(borrower.isPublicSector)
              ? capitalize(borrower.isPublicSector)
              : '-',
          },
          {
            label: 'Cell Phone',
            value: formatPhone(borrower.profile?.phone),
          },
          {
            label: 'Permission to SMS',
            value: !isNil(borrower.hasOptedIntoPhoneContact)
              ? yesOrNo(borrower.hasOptedIntoPhoneContact)
              : '-',
          },
        ],
      ],
    },
  ];
};

export const borrowerOnboardingLoanInfoData = ({ borrower }) => {
  return [
    {
      title: 'Loan Info',
      data: [
        [
          {
            label: 'Federal Loans',
            value: isNil(borrower.federalLoansEstimate)
              ? '-'
              : dollars(borrower.federalLoansEstimate),
          },
          {
            label: 'Federal In Default?',
            value: isNil(borrower.loansInDefaultFederal)
              ? '-'
              : capitalize(borrower.loansInDefaultFederal),
          },
          {
            label: 'In IDR?',
            value: isNil(borrower.isCurrentlyEnrolledInIdr)
              ? '-'
              : capitalize(borrower.isCurrentlyEnrolledInIdr),
          },
          {
            label: 'Private Loans',
            value: isNil(borrower.privateLoansEstimate)
              ? '-'
              : dollars(borrower.privateLoansEstimate),
          },
          {
            label: 'Private In default',
            value: isNil(borrower.loansInDefaultPrivate)
              ? '-'
              : capitalize(borrower.loansInDefaultPrivate),
          },
        ],
      ],
    },
  ];
};

export const borrowerLoanSummaryData = ({ borrower }, type) => {
  const loans = type => {
    if (type === LOAN_TYPE_FSA) {
      return borrower.loans;
    }
    if (type === LOAN_TYPE_PLAID_FEDERAL) {
      return borrower.plaidLoans.filter(item => item.isFederal);
    }
    return borrower.plaidLoans.filter(item => !item.isFederal);
  };
  const activeLoans = loans(type).filter(loan => loan.principalBalance);
  const weigthedAverageInterestRate = loans => {
    const weightedAverage = avgInterestRate(loans);

    return isEmpty(loans) ? '-' : rateParser(weightedAverage);
  };

  const totalBalance = loans => {
    if (isEmpty(loans)) {
      return '-';
    }
    const totalBalanceArray = activeLoans.map(item => {
      const outstanding = item.outstandingInterestBalance ?? 0;
      return item.principalBalance + outstanding;
    });

    return dollars(sum(totalBalanceArray));
  };

  const monthlyPayment = loans => {
    if (isEmpty(loans)) {
      return '-';
    }
    const isMissingData = some(activeLoans, ['monthlyPayment', null]);

    if (isMissingData) {
      return '-';
    }
    const totalMonthlyPayment = sum(
      activeLoans.map(item => item.monthlyPayment),
    );

    return dollars(totalMonthlyPayment);
  };

  const getActiveLoanCount = loans => {
    if (isEmpty(loans)) {
      return 0;
    }
    return loans?.filter(loan => loan.principalBalance !== 0).length;
  };
  return [
    {
      title: 'Loan Info',
      data: [
        [
          {
            label: `Active Loans`,
            value: getActiveLoanCount(loans(type)),
          },
          {
            label: `Total Outstanding Balance`,
            value: totalBalance(loans(type)),
          },
          {
            label: 'Monthly Payment?',
            value: monthlyPayment(loans(type)),
          },
          {
            label: 'Weighted Avg. Interest Rate',
            value: weigthedAverageInterestRate(loans(type)),
          },
        ],
      ],
    },
  ];
};

export const borrowerLoanDetailData = ({ borrower }, type, isPaid = null) => {
  const formatDate = date =>
    !isNil(date) ? format(parseISO(date), 'LLLL yyyy') : null;
  const getIsFederal = item => (item.isFederal ? 'Federal' : 'Private');
  return type === LOAN_TYPE_FSA
    ? borrower.loans
        ?.filter(item =>
          !isPaid ? item.principalBalance !== 0 : item.principalBalance === 0,
        )
        .map(item => {
          return {
            loanInfo: !isNil(item.type) ? item.type : '-',
            amountBorrowed: dollars(item.disbursedAmount),
            servicer: !isNil(item.servicerName) ? item.servicerName : '-',
            repaymentPlan: !isNil(item.repaymentPlanName)
              ? item.repaymentPlanName
              : '-',
            repaymentBeginDate: !isNil(item.repaymentBeginDate)
              ? item.repaymentBeginDate
              : '-',

            principalBalance: !isNil(item.principalBalance)
              ? dollars(item.principalBalance)
              : '-',
            loanStatus: !isNil(item.loanStatusName) ? item.loanStatusName : '-',
            monthlyPayment: !isNil(item.monthlyPayment)
              ? dollars(item.monthlyPayment)
              : '-',
            recertDate: !isNil(item.idrRecertificationDate)
              ? item.idrRecertificationDate
              : '-',
            outstandingInterest: !isNil(item.outstandingInterestBalance)
              ? dollars(item.outstandingInterestBalance)
              : '-',
            interestRate: !isNil(item.interestRate)
              ? rateParser(item.interestRate)
              : '-',
            loanSource: !isNil(item.source) ? item.source : '-',
            qualifyingPayments: !isNil(item.pslfMatchedMonths)
              ? item.pslfMatchedMonths
              : '-',
            planEdited: isNil(item.planEdited) ? '-' : yesOrNo(item.planEdited),
            caresAct: isCaresActEligible(
              item.loanType,
              (item.servicerInformationRaw || []).map(info => info.name),
            ),
            createdAt: isNil(item.originatedAt)
              ? null
              : formatDate(item.originatedAt),
          };
        })
    : borrower.plaidLoans
        .filter(item =>
          type === LOAN_TYPE_PLAID_FEDERAL ? item.isFederal : !item.isFederal,
        )
        ?.filter(item =>
          !isPaid ? item.principalBalance !== 0 : item.principalBalance === 0,
        )

        .map(item => {
          return {
            loanInfo: !isNil(item.loanName) ? item.loanName : '-',
            amountBorrowed: dollars(item.disbursedAmount),
            servicer: !isNil(item.servicerName) ? item.servicerName : '-',
            repaymentPlan: !isNil(item.repaymentPlanName)
              ? item.repaymentPlanName
              : '-',
            principalBalance: !isNil(item.principalBalance)
              ? dollars(item.principalBalance)
              : '-',
            loanStatus: !isNil(item.loanStatusName) ? item.loanStatusName : '-',
            monthlyPayment: !isNil(item.monthlyPayment)
              ? dollars(item.monthlyPayment)
              : '-',
            isFederal: !isNil(item.isFederal) ? getIsFederal(item) : '-',
            dateBorrowed: !isNil(item.dateBorrowed) ? item.dateBorrowed : '-',
            balance: !isNil(item.currentBalance) ? item.currentBalance : '-',
            // We dont get this from Plaid
            repaymentBeginDate: '-',

            outstandingInterest: !isNil(item.outstandingInterestBalance)
              ? dollars(item.outstandingInterestBalance)
              : '-',
            interestRate: !isNil(item.interestRate)
              ? rateParser(item.interestRate)
              : '-',
            isActive: !isNil(item.active) ? item.active : '-',
            // We dont get this from Plaid
            pslfMatchedMonths: '-',
            createdAt: isNil(item.createdAt)
              ? null
              : formatDate(item.createdAt),
          };
        });
};

export const borrowerPaidLoanDetailData = ({ borrower }, type) => {
  const borrowerClone = { ...borrower };
  borrowerClone.loans = borrower.loans?.filter(
    item => item.principalBalance === 0,
  );
  borrowerClone.plaidLoans = borrower.plaidLoans?.filter(
    item => item.principalBalance === 0,
  );
  return borrowerLoanDetailData({ borrower: borrowerClone }, type, true);
};

export const borrowerTuayInfo = ({ borrower }) => {
  const mostRecentIDR = borrower.forms.filter(
    item => item.formType === 'idr',
  )[0];
  const IDRForms = borrower.forms.filter(item => item.formType === 'idr');

  return [
    {
      title: 'borrower Tuay Info',
      data: [
        [
          {
            label: 'Marital status',
            value: !isNil(borrower.financialProfile?.maritalStatus)
              ? capitalize(borrower.financialProfile.maritalStatus)
              : '-',
          },
          {
            label: 'Dependents',
            value: !isNil(borrower.financialProfile?.numberOfDependents)
              ? borrower.financialProfile.numberOfDependents
              : '-',
          },
          {
            label: 'Kids',
            value: !isNil(borrower.financialProfile?.numberOfKids)
              ? borrower.financialProfile.numberOfKids
              : '-',
          },
          {
            label: 'File jointly with spouse?',
            value: !isNil(borrower.financialProfile?.jointTaxReturn)
              ? capitalize(borrower.financialProfile.jointTaxReturn)
              : '-',
          },
          {
            label: 'Income changed?',
            value: !isNil(borrower.financialProfile?.changeInIncome)
              ? capitalize(borrower.financialProfile.changeInIncome)
              : '-',
          },
          {
            label: 'AGI (last year)',
            value: !isNil(borrower.financialProfile.adjustedGrossIncome)
              ? dollars(borrower.financialProfile.adjustedGrossIncome)
              : '-',
          },
          {
            label: 'AGI (expected)',
            value: !isNil(borrower.financialProfile?.expectedAnnualGrossIncome)
              ? dollars(borrower.financialProfile.expectedAnnualGrossIncome)
              : '-',
          },
        ],
        [
          {
            label: 'Joint AGI (last year)',
            value: !isNil(borrower.financialProfile.jointAdjustedGrossIncome)
              ? dollars(borrower.financialProfile.jointAdjustedGrossIncome)
              : '-',
          },
          {
            label: 'Currently employed?',
            value: !isNil(borrower.financialProfile.currentlyEmployed)
              ? yesOrNo(borrower.financialProfile.currentlyEmployed)
              : '-',
          },
          {
            label: 'Pay frequency',
            value:
              !isEmpty(IDRForms) && !isNil(mostRecentIDR?.form?.payFrequency)
                ? capitalize(mostRecentIDR.form.payFrequency)
                : '-',
          },
          {
            label: 'Perkins forgiveness?',
            value:
              !isEmpty(IDRForms) && !isNil(mostRecentIDR.form.userWillPursuePlc)
                ? yesOrNo(mostRecentIDR.form.userWillPursuePlc)
                : '-',
          },
          {
            label: 'Served in military?',
            value:
              !isEmpty(IDRForms) && !isNil(mostRecentIDR.form.servedInMilitary)
                ? yesOrNo(mostRecentIDR.form.servedInMilitary)
                : '-',
          },
          {
            label: 'Servicer for consolidation',
            value:
              !isEmpty(IDRForms) &&
              !isNil(mostRecentIDR.form.targetServicerName)
                ? mostRecentIDR.form.targetServicerName
                : '-',
          },
          { label: '', value: '' },
        ],

        [
          {
            label: 'Were any loans used for grad school?',
            value: !isNil(borrower.financialProfile.hasLoansFromGradSchool)
              ? borrower.financialProfile.hasLoansFromGradSchool
              : '-',
          },
          {
            label: 'Are you currently in IDR?',
            value: !isNil(borrower.isCurrentlyEnrolledInIdr)
              ? capitalize(borrower.isCurrentlyEnrolledInIdr)
              : '-',
          },
          {
            label: 'Which IDR plan',
            value: borrower?.financialProfile?.repaymentPlan?.shortName ?? '-',
          },

          {
            label: 'IDR Recertification Date',
            value: !isNil(borrower.financialProfile.idrRecertDate)
              ? format(
                  parseISO(borrower.financialProfile.idrRecertDate),
                  'LL dd, yy',
                )
              : '-',
          },

          {
            label: 'Consolidated Loans Include Parent Plus',
            value: !isNil(
              borrower.financialProfile.consolidationIncludedParentPlus,
            )
              ? yesOrNo(
                  borrower.financialProfile.consolidationIncludedParentPlus,
                )
              : '-',
          },
        ],
      ],
    },
  ];
};

export const borrowerUnemploymentInfo = ({ borrower }) => {
  return [
    {
      title: 'Unemployment Info',
      data: [
        [
          {
            label: 'Unemployment income',
            value: !isNil(borrower.financialProfile?.unemploymentIncome)
              ? dollars(borrower.financialProfile.unemploymentIncome * 12)
              : '-',
          },
          {
            label: 'Monthly unemployment income',
            value: !isNil(borrower.financialProfile?.unemploymentIncome)
              ? dollars(borrower.financialProfile.unemploymentIncome)
              : '-',
          },
          {
            label: 'Unemployment income end date',
            value: !isNil(borrower.financialProfile?.unemploymentEndDate)
              ? borrower.financialProfile.unemploymentEndDate
              : '-',
          },
          {
            label: 'Other income',
            value: !isNil(
              borrower.financialProfile?.expectedNonUnemploymentIncome,
            )
              ? dollars(borrower.financialProfile.expectedNonUnemploymentIncome)
              : '-',
          },
        ],
      ],
    },
  ];
};

export const borrowerSpouseInfo = ({ borrower }) => {
  return [
    {
      title: 'Spouse Info',
      data: [
        [
          {
            label: 'Federal student loans',
            value: isNil(borrower.financialProfile?.spouseHasFsl)
              ? '-'
              : yesOrNo(borrower.financialProfile.spouseHasFsl),
          },
          {
            label: 'Federal loan balance',
            value: isNil(borrower.financialProfile?.spouseCurrentLoanBalance)
              ? '-'
              : dollars(borrower.financialProfile.spouseCurrentLoanBalance),
          },
          {
            label: 'Income change?',
            value: !isNil(borrower.financialProfile?.spouseIncomeChange)
              ? capitalize(borrower.financialProfile.spouseIncomeChange)
              : '-',
          },
          {
            label: 'AGI (adjusted)',
            value: !isNil(borrower.financialProfile?.spouseAdjustedGrossIncome)
              ? dollars(borrower.financialProfile.spouseAdjustedGrossIncome)
              : '-',
          },
          {
            label: 'AGI (expected)',
            value: !isNil(
              borrower.financialProfile?.spouseExpectedAnnualGrossIncome,
            )
              ? dollars(
                  borrower.financialProfile.spouseExpectedAnnualGrossIncome,
                )
              : '-',
          },
          {
            label: 'Spouse employed?',
            value: !isNil(borrower.financialProfile?.spouseCurrentlyEmployed)
              ? yesOrNo(borrower.financialProfile.spouseCurrentlyEmployed)
              : '-',
          },
          {
            label: 'Pay frequency',
            value: !isNil(borrower.financialProfile?.spousePayFrequency)
              ? capitalize(borrower.financialProfile.spousePayFrequency)
              : '-',
          },
          {
            label: '',
            value: '',
          },
          {
            label: '',
            value: '',
          },
        ],
      ],
    },
  ];
};

export const borrowerSpouseUnemploymentInfo = ({ borrower }) => {
  return [
    {
      title: 'Spouse Unemployment Info',
      data: [
        [
          {
            label: 'Unemployment income',
            value: !isNil(borrower.financialProfile?.spouseUnemploymentIncome)
              ? dollars(borrower.financialProfile.spouseUnemploymentIncome * 12)
              : '-',
          },
          {
            label: 'Monthly unemployment income',
            value: !isNil(borrower.financialProfile?.spouseUnemploymentIncome)
              ? dollars(borrower.financialProfile.spouseUnemploymentIncome)
              : '-',
          },
          {
            label: 'Unemployment income end date',
            value: !isNil(borrower.financialProfile?.spouseUnemploymentEndDate)
              ? borrower.financialProfile.spouseUnemploymentEndDate
              : '-',
          },
          {
            label: 'Other income',
            value: !isNil(
              borrower.financialProfile?.spouseExpectedNonUnemploymentIncome,
            )
              ? dollars(
                  borrower.financialProfile.spouseExpectedNonUnemploymentIncome,
                )
              : '-',
          },
        ],
      ],
    },
  ];
};

export const borrowerIDRFormSummaryData = ({ borrower }) => {
  return borrower.forms
    .filter(f => isIdr(f.formType))
    .map(form => ({
      ...form,
      firstName: borrower.profile.firstName,
      lastName: borrower.profile.lastName,
      borrowerId: borrower.id,
    }));
};
export const borrowerPSLFFormSummaryData = ({ borrower }) => {
  return borrower.forms
    .filter(f => isPslf(f.formType))
    .map(form => ({
      ...form,
      firstName: borrower.profile.firstName,
      lastName: borrower.profile.lastName,
      borrowerId: borrower.id,
    }));
};
export const borrowerRecommendPlansData = ({ borrower }) => {
  const getPaths = () => {
    const selectedPaths = [];
    const currentPaths = [];

    borrower?.forms?.forEach(form => {
      const currentPathId = form?.form?.currentPath?.id;
      const selectedPathId = form?.form?.selectedPath?.id;

      if (!selectedPaths.includes(selectedPathId)) {
        selectedPaths.push(selectedPathId);
      }

      if (!currentPaths.includes(currentPathId)) {
        currentPaths.push(currentPathId);
      }
    });
    return {
      selectedPaths,
      currentPaths,
    };
  };

  const getReason = () => {
    const batchResult = borrower?.pathBatch?.blockedReason
      ? {
          blockedReason: borrower?.pathBatch?.blockedReason,
          batchId: borrower?.pathBatch.id,
        }
      : null;
    const pathResult = borrower?.forms
      ?.filter(form => isIdr(form?.formType))
      ?.map(form => ({
        blockedReason: form?.form?.selectedPath?.blockedReason,
        pathId: form?.form?.selectedPath?.id,
      }));
    const reasons =
      !batchResult || !pathResult
        ? []
        : [batchResult, ...pathResult].map(reason => ({
            blockedReason: reason?.blockedReason,
            batchId: reason?.batchId ?? '-',
            pathId: reason?.pathId ?? '-',
          }));
    return reasons;
  };

  const paths = !isEmpty(getReason())
    ? getReason()
    : borrower?.pathBatch?.paths?.map(item => {
        return {
          planType: !isNil(item.planType) ? item.planType[0] : '-',
          isSelected: getPaths(borrower).selectedPaths.includes(item.id)
            ? 'Yes'
            : '-',
          isCurrent: getPaths(borrower).currentPaths.includes(item.id)
            ? 'Yes'
            : '-',
          firstPayment: !isNil(item.firstPayment)
            ? dollars(item.firstPayment)
            : '-',
          lastPayment: !isNil(item.lastPayment)
            ? dollars(item.lastPayment)
            : '-',
          totalPayment: !isNil(item.totalPayment)
            ? dollars(item.totalPayment)
            : '-',
          amountForgiven: !isNil(item.amountForgiven)
            ? dollars(item.amountForgiven)
            : '-',
          noOfPayments: !isNil(item.numPayments) ? item.numPayments : '-',
          consolidation: isNil(item.requiresConsolidation)
            ? '-'
            : yesOrNo(item.requiresConsolidation),
          withPSLF: isNil(item.isPSLF) ? '-' : yesOrNo(item.isPSLF),
          appType: isNil(item.appType) ? '-' : item.appType,
          recommendationReasons: isNil(item.recommendedReasons)
            ? '-'
            : item.recommendedReasons,
        };
      });
  return sortBy(paths, ['isSelected', 'isCurrent']).reverse();
};
