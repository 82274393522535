/* eslint-disable react/display-name */
import { isNil, isEmpty, startCase } from 'lodash';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import {
  Header,
  Icon,
  Cell,
  FlexRow,
  Collapse,
  ArrowUp,
  TooltipFloater,
  Text,
} from '@summer/ui-components';

import { Box } from 'components/box/Box';
import NoDataToDisplay from 'components/placeholders/NoDataToDisplay';
import CardTable from 'components/table/SortableCardTable';
import { LOAN_TYPE_FSA } from 'utils/constants';
import { getIsSplittable } from 'utils/general';

const BorrowerLoanDetails = ({ activeloanInfo, paidLoanInfo, type }) => {
  const containerRef = useRef();
  const createLoanInfo = ({ isActive, cell, row }) => {
    const { amountBorrowed, createdAt } = row.original;
    const dateBorrowedText = isActive ? 'borrowed' : 'disbursed';
    const moreInfo = createdAt
      ? `${amountBorrowed} ${dateBorrowedText} at ${createdAt}`
      : `${dateBorrowedText} ${amountBorrowed}`;

    return (
      <TooltipFloater
        placement="bottom"
        content={() => <Text small>{cell.value}</Text>}
        width={200}
      >
        <Cell
          size="small"
          type="string"
          value={cell.value}
          moreInfoSize="extraSmall"
          moreInfo={!isNil(amountBorrowed) ? moreInfo : null}
          useCustomTooltip={true}
        />
      </TooltipFloater>
    );
  };

  const createInterestRate = ({ cell, row }) => {
    const { interestRate } = row.original;
    const rateText = 'Rate';
    const Rate = ` ${rateText}: ${interestRate}`;
    return (
      <TooltipFloater
        placement="bottom"
        content={() => <Text small>{cell.value}</Text>}
        width={200}
      >
        <Cell
          size="small"
          type="money"
          value={`${cell.value}`}
          moreInfoSize="extraSmall"
          align="left"
          moreInfo={!isNil(interestRate) ? Rate : null}
          useCustomTooltip={true}
        />
      </TooltipFloater>
    );
  };
  const createRepaymentPlan = ({ cell, row }) => {
    const { planEdited } = row.original;
    const editedText = 'Edited?';
    const Rate = ` ${editedText}: ${planEdited}`;

    return (
      <Cell
        size="small"
        type="string"
        value={cell.value}
        moreInfoSize="extraSmall"
        align="left"
        moreInfo={!isNil(planEdited) ? Rate : null}
        useCustomTooltip={true}
      />
    );
  };
  const columns = useMemo(() => {
    return type === LOAN_TYPE_FSA
      ? [
          {
            Header: 'Loan Info',
            accessor: 'loanInfo',
            Cell: ({ cell, row }) =>
              createLoanInfo({ isActive: true, cell, row }),
            width: 180,
            maxWidth: 180,
            sortable: true,
            sortDescFirst: true,
          },
          {
            Header: 'Servicer',
            accessor: 'servicer',
            width: 80,
            Cell: ({ cell }) => (
              <TooltipFloater
                placement="bottom"
                content={() => <Text small>{cell.value}</Text>}
                width={200}
                style={
                  getIsSplittable(cell.value) ? { display: 'block' } : null
                }
              >
                <Cell type="string" value={cell.value} size="small" />
              </TooltipFloater>
            ),
          },

          {
            Header: 'Repayment Plan',
            accessor: 'repaymentPlan',
            Cell: ({ cell, row }) => createRepaymentPlan({ cell, row }),
            width: 80,
          },
          { Header: 'Principal Balance', accessor: 'principalBalance' },
          {
            Header: 'Loan Status',
            accessor: 'loanStatus',
            width: 100,
            sortable: true,
            sortDescFirst: true,
            Cell: ({ cell }) => (
              <TooltipFloater
                placement="bottom"
                content={() => <Text small>{cell.value}</Text>}
                width={200}
                style={
                  getIsSplittable(cell.value) ? { display: 'block' } : null
                }
              >
                <Cell
                  type="string"
                  size="small"
                  value={cell.value}
                  tooltip={cell.value}
                  useCustomTooltip={true}
                />
              </TooltipFloater>
            ),
          },
          {
            Header: 'Monthly Payment',
            accessor: 'monthlyPayment',
          },
          {
            Header: 'Repayment Begin Date',
            accessor: 'repaymentBeginDate',
          },
          {
            Header: 'Recert Date',
            accessor: 'recertDate',
          },
          {
            Header: 'Qualifying Payments',
            accessor: 'qualifyingPayments',
          },
          {
            Header: 'Outstanding Interest',
            accessor: 'outstandingInterest',
            Cell: ({ cell, row }) => createInterestRate({ cell, row }),
            width: 80,
          },
          {
            Header: 'Cares Act',
            accessor: 'caresAct',
          },
          {
            Header: 'Loan Source',
            accessor: 'loanSource',
          },
        ]
      : [
          {
            Header: 'Loan Name',
            accessor: 'loanInfo',
            Cell: ({ cell, row }) =>
              createLoanInfo({ isActive: false, cell, row }),
            width: 180,
            maxWidth: 180,
            sortable: true,
            sortDescFirst: true,
          },
          {
            Header: 'Institution Name',
            accessor: 'servicer',
            Cell: ({ cell }) => (
              <TooltipFloater
                placement="bottom"
                content={() => <Text small>{cell.value}</Text>}
                width={200}
                style={
                  getIsSplittable(cell.value) ? { display: 'block' } : null
                }
              >
                <Cell
                  type="string"
                  value={cell.value}
                  size="small"
                  useCustomTooltip={true}
                />
              </TooltipFloater>
            ),
          },
          { Header: 'Principal Balance', accessor: 'principalBalance' },
          {
            Header: 'Loan Status',
            accessor: 'loanStatus',
            width: 100,
            sortable: true,
            sortDescFirst: true,
            Cell: ({ cell }) => (
              <TooltipFloater
                placement="bottom"
                content={() => <Text small>{cell.value}</Text>}
                width={200}
                style={
                  getIsSplittable(cell.value) ? { display: 'block' } : null
                }
              >
                <Cell
                  type="string"
                  size="small"
                  value={cell.value}
                  tooltip={cell.value}
                  useCustomTooltip={true}
                />
              </TooltipFloater>
            ),
          },
          {
            Header: 'Monthly Payment',
            accessor: 'monthlyPayment',
          },

          {
            Header: 'Repayment Begin Date',
            accessor: 'repaymentBeginDate',
          },
          {
            Header: 'Outstanding Interest',
            accessor: 'outstandingInterest',
            Cell: ({ cell, row }) => createInterestRate({ cell, row }),
            width: 80,
          },
          {
            Header: 'Origination Principal Amount',
            accessor: 'amountBorrowed',
          },
          { Header: 'Origination Date', accessor: 'dateBorrowed' },

          {
            Header: 'Repayment Plan Type',
            accessor: 'repaymentPlan',
          },
          { Header: 'Federal vs Private', accessor: 'isFederal' },
        ];
  }, [type]);

  return (
    <>
      <Box mt={40} ref={containerRef}>
        <Collapse
          openByDefault={true}
          trigger={({ openState }) => (
            <CollapseTrigger>
              <Header size="large" weight="bold">
                Loan Details – {startCase(type)}
              </Header>
              <Arrow fill="#6D7581" open={openState} SvgComponent={ArrowUp} />
            </CollapseTrigger>
          )}
        >
          {isEmpty(activeloanInfo) ? (
            <Container mt={24}>
              <NoDataToDisplay />
            </Container>
          ) : (
            <TableContainer borderColor="blueGrey" mt={24}>
              <CardTable
                tableName="loanDetails"
                columns={columns}
                data={activeloanInfo}
                colDefaultWidth={80}
                limitColWidth={true}
                virtualizedOptions={{
                  height: 94 * activeloanInfo.length + 1,
                }}
                align="left"
                style={{
                  left: 0,
                  overflowX: 'visible',
                  overflowY: 'auto',
                  flex: 0,
                }}
                getCustomRowProps={props => {
                  // combine row props With our custom row props
                  const style = {
                    ...props.style,
                    left: 0,
                    width: '100%',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  };
                  return {
                    ...props,
                    style,
                  };
                }}
              />
            </TableContainer>
          )}
        </Collapse>
      </Box>
      <Box mt={40}>
        <Collapse
          openByDefault={false}
          trigger={({ openState }) => (
            <CollapseTrigger>
              <Header h4>Paid Off Loans – {startCase(type)}</Header>
              <Arrow fill="#6D7581" open={openState} SvgComponent={ArrowUp} />
            </CollapseTrigger>
          )}
        >
          {isEmpty(paidLoanInfo) ? (
            <Container mt={24}>
              <NoDataToDisplay />
            </Container>
          ) : (
            <>
              <TableContainer borderColor="blueGrey" mt={24}>
                <CardTable
                  columns={columns}
                  data={paidLoanInfo}
                  virtualizedOptions={{
                    height: 94 * paidLoanInfo.length + 1,
                  }}
                  align="left"
                  colDefaultWidth={80}
                  limitColWidth={true}
                  style={{
                    left: 0,
                    overflowX: 'visible',
                    overflowY: 'auto',
                  }}
                  getCustomRowProps={props => {
                    // combine row props With our custom row props
                    const style = {
                      ...props.style,
                      left: 0,
                      width: '100%',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    };
                    return {
                      ...props,
                      style,
                    };
                  }}
                />
              </TableContainer>
            </>
          )}
        </Collapse>
      </Box>
    </>
  );
};

const Container = styled(Box)`
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const TableContainer = styled(Box)``;
const CollapseTrigger = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  padding: 16px 0;
  margin-bottom: 24px;
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  margin-left: 8px;
  transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-out;
`;

export default BorrowerLoanDetails;
