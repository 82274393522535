import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '@summer/ui-components/src/core/Icon';

import { ReactComponent as BorrowerSearchIcon } from 'assets/icons/borrower-search.svg';
import { ReactComponent as FormIcon } from 'assets/icons/form-icon.svg';
import AuthBubble from 'components/auth/AuthBubble';
import { Box, Flex } from 'components/box/Box';
import { getThemeColor } from 'utils/theme-helpers';

const SideBar = () => {
  return (
    <SideBarContainer bg="darkGreen">
      <SideBarNavList>
        <SideBarNavItems to="/borrowers">
          <Icon SvgComponent={BorrowerSearchIcon} />
        </SideBarNavItems>
        <SideBarNavItems to="/forms">
          <Icon SvgComponent={FormIcon} />
        </SideBarNavItems>
      </SideBarNavList>
    </SideBarContainer>
  );
};

const SideBarContainer = styled(Box)`
  width: 54px;
  height: 100vh;
`;
const SideBarNavList = styled.div``;
const SideBarNavItems = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  cursor: pointer;
  .icon * {
    fill: ${getThemeColor('white')};
  }

  :hover,
  &.active {
    background-color: ${getThemeColor('white')};
    .icon * {
      fill: ${getThemeColor('darkGreen')};
    }
  }
`;

const AdminLayout = ({ children, adminUser }) => {
  return (
    <Flex width={1} height="100%" overflow="hidden">
      <SideBar />
      <Box
        position="relative"
        width={1}
        height="100%"
        overflow="scroll"
        bg="lightestGrey"
        px={48}
        pt={24}
      >
        {children}
        <AuthBubble adminUser={adminUser} />
      </Box>
    </Flex>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.element,
  adminUser: PropTypes.any,
};

export default AdminLayout;
