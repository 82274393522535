import { lowerCase } from 'lodash';

import { FormTypes } from '@simplifidev/shared/dist/constants/forms';

export const isPslf = formType => lowerCase(formType) === FormTypes.pslf;
export const isIdr = formType => lowerCase(formType) === FormTypes.idr;

// Client-side filters
export const UPDATED_BY_BORROWER = 'Updated by Borrower';
export const LOAN_TYPE_FSA = 'FSA';
export const LOAN_TYPE_PLAID_FEDERAL = 'plaid federal';
export const LOAN_TYPE_PLAID_PRIVATE = 'plaid private';
