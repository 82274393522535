import React from 'react';

import { DataDisplay, Header } from '@summer/ui-components';

import { Box } from 'components/box/Box';

const BorrowerOnboardingInfo = ({ borrowerInfo, loanInfo }) => {
  return (
    <>
      <Box mt={40}>
        <Header size="large" weight="bold">
          Onboarding Info (Self Reported)
        </Header>
        <Box
          bg="white"
          border="solid 1px"
          borderRadius={6}
          borderColor="blueGrey"
          px={24}
          pt={24}
          mt={24}
        >
          <DataDisplay data={borrowerInfo} />
          <DataDisplay data={loanInfo} />
        </Box>
      </Box>
    </>
  );
};

export default BorrowerOnboardingInfo;
