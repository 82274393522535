import { get, forEach, last } from 'lodash';
import { useMemo } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { api } from 'services/api';

/**
 *
 * Api requests for React-Query
 *
 */

const fetchBorrowers = async ({ filters, config, pageParam }) => {
  const { borrowerId, email, firstName, lastName } = filters;
  const result = await api.get('/borrowers', {
    params: {
      borrowerId: borrowerId === '' ? null : borrowerId,
      email: email === '' ? null : email,
      firstName: firstName === '' ? null : firstName,
      lastName: lastName === '' ? null : lastName,
      limit: get(config, 'limit', 20),
      offset: pageParam ?? 0,
    },
  });

  return {
    borrowers: result?.data.data,
    nextOffset: result?.data.nextOffset,
    count: result?.data.count,
  };
};

const fetchBorrower = async ({ borrowerId }) => {
  const borrowerRes = await api.get(`/borrowers/${borrowerId}`);

  return borrowerRes?.data.data;
};

const deleteBorrower = async ({ borrowerId }) => {
  const borrowerRes = await api.delete(`/borrowers/${borrowerId}`);

  return borrowerRes?.success;
};

/**
 *
 * Custom Hooks with React-Query
 *
 */

export const useBorrowers = ({ filters = {}, config = { limit: 20 } } = {}) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['borrowers', filters, config],
      queryFn: ({ pageParam }) =>
        fetchBorrowers({ filters, config, pageParam }),
      getNextPageParam: lastPage => {
        return lastPage.nextOffset ?? undefined;
      },
      refetchOnWindowFocus: false,
    });

  const resultArray = data?.pages;

  const borrowers = useMemo(() => {
    const borrowersArr = [];

    forEach(resultArray, result => {
      borrowersArr.push(...result?.borrowers);
    });

    return borrowersArr;
  }, [resultArray]);

  const borrowersTotalCount = last(resultArray)?.count || 0;

  return {
    borrowers,
    borrowersTotalCount,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export const useBorrower = borrowerId => {
  return useQuery({
    queryKey: ['borrower', borrowerId],
    queryFn: () => fetchBorrower({ borrowerId }),
    staleTime: 1000 * 60 * 15,
    enabled: typeof borrowerId === 'string',
  });
};

export const useMutateBorrower = action => {
  const queryClient = useQueryClient();
  let query;
  switch (action) {
    case 'delete':
      query = deleteBorrower;
      break;
    default:
  }

  return useMutation({
    mutationKey: ['deleteBorrower'],
    mutationFn: query,
    onSuccess: async (_, variables) => {
      // borrowerId need to be string because when we create the user query we use borrowerId from query params
      await queryClient.invalidateQueries([
        'borrower',
        `${variables.borrowerId}`,
      ]);
    },
  });
};
