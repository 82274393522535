import React from 'react';
import styled from 'styled-components';

import { DataDisplay, Header } from '@summer/ui-components';

import { Box } from 'components/box/Box';
import NoDataToDisplay from 'components/placeholders/NoDataToDisplay';

const BorrowerOnboardingInfo = ({
  tuayInfo,
  unemploymentInfo,
  spouseInfo,
  spouseUnemploymentInfo,
  borrowerHasFinancialProfile,
}) => {
  return (
    <>
      <Box mt={40}>
        <Header size="large" weight="bold">
          IDR Info (Self-reported)
        </Header>
        {!borrowerHasFinancialProfile ? (
          <Container height={137} mt={24} mb={30}>
            <NoDataToDisplay />
          </Container>
        ) : (
          <Box
            bg="white"
            border="solid 1px"
            borderRadius={6}
            borderColor="blueGrey"
            px={24}
            pt={24}
            mt={24}
          >
            <DataDisplay data={tuayInfo} />
            <DataDisplay data={unemploymentInfo} />
            <DataDisplay data={spouseInfo} />
            <DataDisplay data={spouseUnemploymentInfo} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default BorrowerOnboardingInfo;

const Container = styled(Box)`
  max-height: 137px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  div {
    max-height: 137px;
  }
`;
