import React from 'react';
import styled from 'styled-components';

import { Text } from '@summer/ui-components';

import Loading from 'components/placeholders/Loading';

const DownloadIcon = () => <>&#8595; </>;

/**
 * The disabled state, for ease of reuse if no href is provided.
 *
 * @param {{
 *  displayName: string;
 * }} params
 */
export const DownloadFileDisabled = ({ displayName }) => (
  <Container>
    <Text bold color="secondary">
      <DownloadIcon /> {displayName} Unavailable
    </Text>
  </Container>
);

/**
 * A generic download button design.
 *
 * @param {{
 *  isLoading?: boolean;
 *  disabled?: boolean;
 *  href: string;
 *  displayName: string;
 * }} params
 */
const DownloadFile = ({ isLoading, href, disabled, displayName }) => {
  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (disabled) {
    return <DownloadFileDisabled displayName={displayName} />;
  }

  return (
    <Container>
      <Text
        as="a"
        color="summer"
        bold
        href={href}
        taret="_blank"
        rel="noopener noreferrer"
      >
        <DownloadIcon /> {displayName}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  a {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default DownloadFile;
