/* eslint-disable react/display-name */
import { get, join } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Cell, Header, Text, TooltipFloater } from '@summer/ui-components';

import { Box } from 'components/box/Box';
import DateCell from 'components/cells/DateCell';
import CardTable from 'components/table/SortableCardTable';
import FormView from 'views/Form/FormView';

const BorrowerIDRFormSummaryTable = ({ forms }) => {
  const containerRef = useRef();
  const [formId, setFormId] = useState(null);

  const getDate = row => {
    switch (row.original.status) {
      case 'started':
        return row.original.createdAt;
      case 'signed':
        return row.original.signedAt;
      case 'processed':
        return row.original.dateProcessedDetected;
      default:
        return null;
    }
  };
  const columns = useMemo(() => {
    return [
      {
        Header: 'Date Received',
        accessor: 'signedAt',
        Cell: ({ cell }) => (
          <DateCell value={cell.value} size="small" formatString="MMM d yyyy" />
        ),
      },
      {
        Header: 'Consolidation?',
        accessor: 'form.isConsolidation',
        Cell: ({ cell }) => (
          <Cell type="string" size="small" value={cell.value ? 'Yes' : 'No'} />
        ),
      },

      {
        Header: 'Current Plan',
        accessor: 'form.currentPath',

        Cell: ({ cell }) => (
          <TooltipFloater
            placement="bottom"
            content={() => (
              <Text small>{cell.value ? join(cell.value, ', ') : '-'}</Text>
            )}
            width={200}
            style={{ display: 'block' }}
          >
            <Cell
              type="string"
              value={cell.value ? join(cell.value, ', ') : '-'}
              useCustomTooltip={true}
            />
          </TooltipFloater>
        ),
      },
      {
        Header: 'New Plan',
        accessor: 'form.selectedPath',
        Cell: ({ cell }) => (
          <Text weight="medium" color="dark " size="small">
            {cell.value ? join(cell.value, ', ') : '-'}
          </Text>
        ),
      },
      {
        Header: 'Servicer',
        accessor: 'form.servicersToSendIDR',
        Cell: ({ cell }) => (
          <TooltipFloater
            placement="bottom"
            content={() => (
              <Text small>{cell.value ? join(cell.value, ', ') : '-'}</Text>
            )}
            width={200}
            style={{ display: 'block' }}
          >
            <Cell
              type="string"
              value={cell.value ? join(cell.value, ', ') : '-'}
              useCustomTooltip={true}
              size="small"
            />
          </TooltipFloater>
        ),
      },

      {
        Header: 'Servicer for Consolidation',
        accessor: 'form.targetServicerName',
      },
      {
        Header: 'App Type',
        accessor: 'form.selectedPath.appType',
      },

      {
        Header: 'Status',
        accessor: 'metadata.status',
        Cell: ({ cell }) => (
          <Cell type="string" size="small" value={cell.value} />
        ),
      },
      {
        Header: 'Status Date',
        accessor: 'metadata.statusDate',
        Cell: ({ row }) =>
          getDate(row) ? (
            <DateCell
              value={getDate(row)}
              size="small"
              formatString="MMM d yyyy"
            />
          ) : (
            <Text weight="medium" color="dark">
              -
            </Text>
          ),
      },
      {
        Header: 'Owner',
        accessor: 'metadata.owner.name',
        Cell: ({ cell }) => (
          <Cell type="string" size="small" value={cell.value} />
        ),
      },
    ];
  }, []);

  return (
    <Box mt={32} mb={32} ref={containerRef}>
      <Header size="large" weight="bold">
        IDR Application Forms
      </Header>
      <TableContainer borderColor="blueGrey" mt={24}>
        <CardTable
          columns={columns}
          data={forms}
          colDefaultWidth={80}
          limitColWidth={true}
          virtualizedOptions={{
            height: 94 * forms.length + 1,
          }}
          align="left"
          style={{
            left: 0,
            overflowX: 'visible',
            overflowY: 'auto',
            flex: 0,
          }}
          getCustomRowProps={(props, options) => {
            const { row } = options;
            const formId = get(row, 'original.id');
            // combine row props With our custom row props
            const style = {
              ...props.style,
              left: 0,
              width: '100%',
              paddingLeft: '8px',
              paddingRight: '8px',
            };
            return {
              ...props,
              style,
              onClick: () => {
                setFormId(formId);
              },
            };
          }}
        />
      </TableContainer>
      {formId && (
        <FormView
          formId={formId}
          isPopupOpen={!!formId}
          togglePopup={() => setFormId(null)}
          isModal={true}
        />
      )}
    </Box>
  );
};
const TableContainer = styled(Box)``;

export default BorrowerIDRFormSummaryTable;
