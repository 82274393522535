import { format, parseISO } from 'date-fns';
import { get, toUpper } from 'lodash';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { FormTypes } from '@simplifidev/shared/dist/constants/forms';

import {
  ColorBadge,
  Text,
  Header,
  Icon,
  CheckSmall,
} from '@summer/ui-components';

import { Box, Flex } from 'components/box/Box';
import { formSignedAgainRecently } from 'utils/forms';
import { getThemeColor } from 'utils/theme-helpers';

import FormDownload from './FormDownload';

const FormHeader = ({ form }) => {
  const theme = useTheme();
  const {
    borrower,
    signedAt,
    formType = '',
    status,
    dateProcessedDetected,
    form: extraFormData,
  } = form;

  const badgeColor = formType === FormTypes.pslf ? 'gold' : 'magenta';
  const isConsolidation = get(extraFormData, 'isConsolidation');
  const receivedDate = signedAt
    ? format(parseISO(signedAt), 'LLL d, yyyy')
    : '-';
  const processedDate = dateProcessedDetected
    ? format(parseISO(dateProcessedDetected), 'LLL d, yyyy')
    : '-';

  const openBorrowerDetailsInNewTab = id => {
    window.open(`/borrowers/${id}`, '_blank');
  };

  return (
    <Box>
      <Flex mb={16} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Box mr="8px">
            <ColorBadge colorName={badgeColor}>{toUpper(formType)}</ColorBadge>
          </Box>
          <Text size="large">
            {isConsolidation ? 'With Consolidation' : ''}
          </Text>
        </Flex>
        <Flex>
          <Box>
            <Text>
              {status === 'started' && 'Edits In Progress | '}
              {formSignedAgainRecently(form)
                ? 'Updated by Borrower: '
                : 'Received: '}
              {receivedDate}
            </Text>
          </Box>
          {status === 'processed' && (
            <Flex alignItems="center" ml={4}>
              <Box marginRight={2}>
                <Icon
                  width={12}
                  height={12}
                  SvgComponent={CheckSmall}
                  stroke={getThemeColor('jade')({ theme })}
                />
              </Box>
              <Text>Accepted: {processedDate}</Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Box>
        <NameHeader onClick={() => openBorrowerDetailsInNewTab(borrower.id)}>
          {borrower?.firstName} {borrower?.lastName} (#{borrower?.id})
        </NameHeader>
      </Box>
      <Flex mt={16} alignItems="center" justifyContent="space-between">
        <Text size="large">{borrower.email}</Text>
        <FormDownload form={form} />
      </Flex>
    </Box>
  );
};

const NameHeader = styled(Header)`
  cursor: pointer;
  &:hover {
    color: #69c2ff;
  }
`;

export default FormHeader;
