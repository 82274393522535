import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import { mockServer } from 'utils/server-mock';
import './assets/fonts/fonts.css';
import './styles/base.css';

import { initErrorTracking } from './errorTracking';

/*
 * This file is our app main entry
 * all js helper and services can load from here
 * */
dotenv.config();
initErrorTracking();
if (process.env.REACT_APP_MOCK_SERVER === 'true') {
  mockServer();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
