import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { TextInput } from '@summer/ui-components';

import { Flex } from 'components/box/Box';

const BorrowersFilters = ({ updateFilters, filters }) => {
  const [IFilters, setIFilters] = useState(filters);

  const debounceUpdateFilters = useMemo(
    () => debounce(updateFilters, 500),
    [updateFilters],
  );

  const handleTextInputChange = useCallback(
    ({ target }) => {
      const clone = { ...IFilters };
      clone[target?.name] = target.value;
      setIFilters(clone);
      debounceUpdateFilters(target?.name, target?.value);
    },
    [setIFilters, IFilters, debounceUpdateFilters],
  );

  return (
    <FilterContainer height="100%" alignItems="center">
      <TextInput
        noFormik
        name="email"
        label="Borrower Email"
        placeholder="Filter by borrower email"
        value={IFilters?.email}
        onChange={handleTextInputChange}
      />
      <TextInput
        noFormik
        name="firstName"
        label="First Name"
        placeholder="Filter by borrower first name"
        value={IFilters?.firstName}
        onChange={handleTextInputChange}
      />
      <TextInput
        noFormik
        name="lastName"
        label="Last Name"
        placeholder="Filter by borrower last name"
        value={IFilters?.lastName}
        onChange={handleTextInputChange}
      />
      <TextInput
        noFormik
        name="borrowerId"
        label="Borrower ID"
        placeholder="Filter by borrower ID"
        type="number"
        value={IFilters?.borrowerId}
        onChange={handleTextInputChange}
      />
    </FilterContainer>
  );
};

const FilterContainer = styled(Flex)`
  > * {
    margin-right: 32px;
    width: 25%;
    :last-child {
      margin-right: 0;
    }
  }
`;

export default BorrowersFilters;
