import React from 'react';
import styled from 'styled-components';

import { Text } from '@summer/ui-components';

import { Flex } from 'components/box/Box';
import { getThemeColor } from 'utils/theme-helpers';

const OAuthButton = ({ onClick }) => {
  return (
    <SocialButton
      onClick={onClick}
      p={16}
      width={1}
      borderRadius={3}
      alignItems="center"
    >
      <SocialIcon src="/images/google-logo.png" />
      <Text large bold>
        Sign in with Google
      </Text>
    </SocialButton>
  );
};

const SocialIcon = styled.img`
  padding-right: 16px;
  height: 24px;
  width: auto;
`;

const SocialButton = styled(Flex)`
  box-shadow: 0 1px 4px 0 ${getThemeColor('medDarkGrey')};

  cursor: pointer;

  &:hover {
    background-color: 0 1px 4px 0 rgba(124, 139, 147, 0.3);
  }
`;

export default OAuthButton;
