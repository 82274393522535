import { COLORS } from '@summer/ui-components/src/helpers/constants/styleguide';

/*
 * This is a test drive of Theme with styled-system and styled-components
 * currently it will work with components that extend button or use styled-system
 * (can be remove later)
 * */

export default {
  colors: {
    azure: COLORS.azure,
    lighterGrey: COLORS.lighterGrey,
    darkGrey: COLORS.darkGrey,
    medGrey: COLORS.medGrey,
    medDarkGrey: COLORS.medDarkGrey,
    grey: COLORS.grey,
    lightestGrey: COLORS.lightestGrey,
    blueGrey: '#ebeff4',
    white: COLORS.white,
    jade: COLORS.jade,
    darkGreen: COLORS.darkGreen,
  },
};
