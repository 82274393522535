import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Text from '@summer/ui-components/src/core/typography/Text';

import { Box, Flex } from 'components/box/Box';
import { useMutateForm } from 'state/server/forms';
import { getThemeColor } from 'utils/theme-helpers';

const FormNotes = ({ formId, formNotes }) => {
  const [isEditable, setEditable] = useState(false);
  const [notes, setNotes] = useState(formNotes || '');
  const { mutateAsync } = useMutateForm();

  const handleEdit = useCallback(async () => {
    setEditable(!isEditable);
    if (isEditable) {
      await mutateAsync({ formId, payload: { notes } });
    }
  }, [formId, mutateAsync, notes, isEditable, setEditable]);

  const handleNotesChange = useCallback(
    e => {
      setNotes(e?.target?.value);
    },
    [setNotes],
  );

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Text>Notes</Text>
        <Click bold onClick={handleEdit} color="summer">
          {isEditable ? 'Save Notes' : 'Edit Notes'}
        </Click>
      </Flex>
      <StyledTextarea
        value={notes}
        onChange={handleNotesChange}
        name="notes"
        disabled={!isEditable}
        placeholder="Add note"
      />
    </Box>
  );
};

const StyledTextarea = styled.textarea`
  height: 95px;
  width: 100%;
  background-color: ${getThemeColor('lightestGrey')};
  font-size: 16px;
  color: ${getThemeColor('darkGrey')};
  padding: 16px;
  border: 0;
`;

const Click = styled(Text)`
  cursor: pointer;
`;

export default FormNotes;
