import { debounce, map } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  SelectInput,
  TextInput,
  Button,
  InfoTooltip,
  Text,
} from '@summer/ui-components';

import { Flex } from 'components/box/Box';
import {
  useAdminUsers,
  usePartners,
  useStatuses,
} from 'state/server/constants';
import { useSelf } from 'state/server/self';
import { UPDATED_BY_BORROWER } from 'utils/constants';
import { arrayToOptions } from 'utils/general';

const FormsFilters = ({ updateFilters, filters, setDialogOpen }) => {
  const [borrowerId, setBorrowerId] = useState(filters.borrowerId);
  const statuses = useStatuses();
  const owners = useAdminUsers();
  const partners = usePartners();

  const statusesForOptions = useMemo(() => {
    const noStatusFilter = [{ status: 'No Status' }];
    return arrayToOptions(map(noStatusFilter.concat(statuses), 'status'));
  }, [statuses]);
  const { self } = useSelf();

  const debounceUpdateFilters = useMemo(
    () => debounce(updateFilters, 500),
    [updateFilters],
  );

  const handleTextInputChange = useCallback(
    ({ target }) => {
      setBorrowerId(target?.value);
      debounceUpdateFilters(target?.name, target?.value);
    },
    [setBorrowerId, debounceUpdateFilters],
  );

  return (
    <FilterContainer height="100%" alignItems="center">
      <TextInput
        noFormik
        name="borrowerId"
        label="Borrower Id"
        placeholder="Filter by borrower ID"
        type="number"
        value={borrowerId}
        onChange={handleTextInputChange}
      />
      <SelectInput
        notFormik
        isClearable
        label="Status"
        name="status"
        placeholder="All Statuses"
        options={statusesForOptions}
        onChange={updateFilters}
        value={filters?.status}
        onBlur={() => {}}
      />
      <SelectInput
        notFormik
        isClearable
        label="Owner"
        name="ownerUuid"
        placeholder="All Owners"
        options={
          owners?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })) || []
        }
        value={filters?.ownerUuid}
        onChange={updateFilters}
        onBlur={() => {}}
      />
      <SelectInput
        notFormik
        isClearable
        label="Partner"
        name="partner"
        placeholder="All Partners"
        options={
          partners?.map(({ name, id }) => ({
            label: name,
            value: id,
          })) ?? []
        }
        value={filters?.partner}
        onChange={updateFilters}
        onBlur={() => {}}
      />
      <SelectInput
        notFormik
        isClearable
        label="Form Type"
        name="formType"
        placeholder="All Form Types"
        options={[
          { label: 'IDR', value: 'idr' },
          { label: 'PSLF', value: 'pslf' },
        ]}
        value={filters?.formType}
        onChange={updateFilters}
        onBlur={() => {}}
      />
      {self?.flags?.enableAdminFax && (
        <SelectInput
          notFormik
          isClearable
          label="Fax Submission Status"
          name="faxSubmissionStatus"
          placeholder="All Fax Statuses"
          options={[
            { label: 'Pending', value: 'pending' },
            { label: 'Success', value: 'success' },
            { label: 'Error', value: 'error' },
          ]}
          value={filters?.faxSubmissionStatus}
          onChange={updateFilters}
          onBlur={() => {}}
        />
      )}
      <div>
        <div>
          <SelectInput
            notFormik
            isClearable
            label={
              <span>
                Loaded Data Filter
                <LoadedDataFilterInfoTooltip />
              </span>
            }
            name="clientSideFilter"
            placeholder=""
            options={[
              { label: 'Updated by Borrower', value: UPDATED_BY_BORROWER },
            ]}
            onChange={updateFilters}
            value={filters?.clientSideFilter}
            onBlur={() => {}}
          />
        </div>
      </div>
      <Button onClick={() => setDialogOpen(true)}>Mark form as signed</Button>
    </FilterContainer>
  );
};

const LoadedDataFilterInfoTooltip = () => (
  <InfoTooltip
    text={
      <>
        <Text small>
          These filters will only display data that was loaded onto the page
          before the filter was applied.
        </Text>
        <Text small>
          To load more data, you can use the other filters to narrow down what
          you&rsquo;re looking for, scroll through the table to load more data,
          then apply a Loaded Data Filter.
        </Text>
      </>
    }
  />
);

const FilterContainer = styled(Flex)`
  flex-wrap: wrap;
  > * {
    width: 200px;
    flex-grow: 1;
    margin-right: 32px;
  }
`;

export default FormsFilters;
