import { useMutation } from 'react-query';

import { api } from 'services/api';

const submitFax = async ({ formId, testFaxNumber }) => {
  const config = testFaxNumber ? { params: { testFaxNumber } } : null;

  const faxRes = await api.post(`/forms/${formId}/fax/submit`, null, config);

  return faxRes?.data.data;
};

export const useMutateSubmitFax = ({ formId }) => {
  return useMutation({
    mutationKey: ['submitFax', formId],
    mutationFn: submitFax,
  });
};
