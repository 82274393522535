import React, { useCallback, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Text } from '@summer/ui-components';

import { Box, Flex } from 'components/box/Box';
import useClickOutside from 'hooks/useClickOutside';
import { logoutSelf } from 'state/server/self';
import { getAdminUserInitials } from 'utils/auth';
import { getThemeColor } from 'utils/theme-helpers';

const AuthBubble = ({ adminUser }) => {
  const queryClient = useQueryClient();
  const authBubbleRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions, setShowOptions]);

  useClickOutside(showOptions, toggleOptions, authBubbleRef);

  return (
    <Box position="absolute" right={48} top={24} ref={authBubbleRef}>
      <Click onClick={toggleOptions}>
        <Flex
          justifyContent="center"
          alignItems="center"
          size={32}
          bg="azure"
          borderRadius="50%"
          overflow="hidden"
        >
          {adminUser?.profileImageUrl ? (
            <ProfileImg src={adminUser?.profileImageUrl} />
          ) : (
            <Text bold size="small" color="light">
              {getAdminUserInitials(adminUser)}
            </Text>
          )}
        </Flex>
      </Click>
      {showOptions && (
        <Options
          bg="white"
          position="absolute"
          right={0}
          top={40}
          px={12}
          py={10}
        >
          <Click onClick={() => logoutSelf(queryClient)}>
            <Text bold>Logout</Text>
          </Click>
        </Options>
      )}
    </Box>
  );
};

const Options = styled(Box)`
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(195, 195, 195, 0.5);
  border: solid 1px ${getThemeColor('grey')};
`;

const Click = styled.div`
  cursor: pointer;
`;

const ProfileImg = styled.img`
  width: 100%;
`;

export default AuthBubble;
