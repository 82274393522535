import { Severity, captureMessage, withScope } from '@sentry/browser';
import { isNil } from 'lodash';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { logoutSelf } from 'state/server/self';
import { parsePathForIdentifiers } from 'utils/error';

export const onErrorHandler = (err, { push }) => {
  const statusCode = err?.response?.status;

  if (
    isNil(statusCode) ||
    statusCode >= 500 ||
    statusCode === 0 ||
    err?.message === 'Network Error'
  ) {
    push(`/error`);
    return;
  }

  if (statusCode === 401) {
    logoutSelf(queryClient);
    return;
  }

  // captures all other 4xx error codes
  if (statusCode >= 400 && statusCode < 500) {
    const errorUrl = new URL(err.response?.request?.responseURL);

    const fingerprintPath = parsePathForIdentifiers(errorUrl.pathname);

    withScope(scope => {
      scope.setFingerprint([fingerprintPath, statusCode]);
      scope.setTag('path', errorUrl.pathname);
      scope.setExtra('response', err.response);
      captureMessage(
        `API Error: ${fingerprintPath} ${err.response.status} ${err.response.statusText}`,
        { level: Severity.Error },
      );
    });
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      /** @todo [sc-37500] use push from useSummerHistory instead. */
      onError: err =>
        onErrorHandler(err, {
          push: route => {
            if (typeof window === 'undefined') {
              // eslint-disable-next-line no-console -- will be remove in [sc-37500]
              console.error(
                `Could not redirect to ${route} as window was undefined`,
              );
            }
            window.location.replace(route);
          },
        }),
    },
  },
});

export const ReactQueryClientProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
