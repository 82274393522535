import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Flex } from 'components/box/Box';
import Error from 'components/errors/Error';
import AdminLayout from 'components/layouts/AdminLayout';
import Loading from 'components/placeholders/Loading';
import { useSelf } from 'state/server/self';
import Login from 'views/Auth/Login';
import BorrowerView from 'views/Borrower/BorrowerView';
import FormView from 'views/Form/FormView';
import FormsView from 'views/Forms/FormsView';
import BorrowersView from 'views/Users/BorrowersView';
import BaseView from 'views/demo/BaseView';

const AppRoutes = () => {
  const { self: adminUser, isLoading, error } = useSelf();

  if (isLoading) {
    return (
      <Flex width={1} height="100%" justifyContent="center" alignItems="center">
        <Loading />
      </Flex>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/error"
        render={props => <Error {...props} error={error} />}
      />
      {!adminUser ? (
        <UnauthenticatedAppRoutes adminUser={adminUser} />
      ) : (
        <AuthenticatedAppRoutes adminUser={adminUser} />
      )}
    </Switch>
  );
};

const AuthenticatedAppRoutes = ({ adminUser }) => {
  return (
    <AdminLayout adminUser={adminUser}>
      <Switch>
        <Route
          exact
          path="/"
          render={props => <BaseView {...props} adminUser={adminUser} />}
        />
        <Route exact path="/forms" component={FormsView} />
        <Route exact path="/forms/:formId" component={FormView} />
        <Route exact path="/borrowers" component={BorrowersView} />
        <Route exact path="/borrowers/:borrowerId" component={BorrowerView} />
        <Redirect to="/" />
      </Switch>
    </AdminLayout>
  );
};

const UnauthenticatedAppRoutes = ({ adminUser }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/login"
          render={props => <Login {...props} adminUser={adminUser} />}
        />
        <Redirect to="/login" />
      </Switch>
    </>
  );
};

export default AppRoutes;
