import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@summer/ui-components';

const DateCell = ({ value, formatString = 'MM / yyyy', align, size }) => {
  return (
    <>
      <Text
        data-testid={`dateCell-${isNil(value) ? '-' : value}`}
        weight="medium"
        color="dark"
        paragraph
        smallTracking
        align={align}
        size={size}
      >
        {isNil(value) ? '-' : format(parseISO(value), formatString)}
      </Text>
    </>
  );
};

DateCell.propTypes = {
  value: PropTypes.string,
  formatString: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right']),
};

export default DateCell;
