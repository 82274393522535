import { get } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';

import { useSummerHistory } from 'hooks/useSummerHistory';
import { api } from 'services/api';

const queryKey = 'self';

// API
const fetchSelf = async () => {
  const selfRes = await api.get('/me');

  return selfRes?.data.data;
};

const requestLogout = async () => api.post('/oauth/logout');

// self state and state helpers
// requires passing queryClient due to react rules of hooks
const clearSelf = queryClient => {
  return queryClient.setQueryData(['self'], null);
};

// requires passing queryClient due to react rules of hooks
export const setSelf = (queryClient, self) => {
  queryClient.setQueryData(['self'], self);
};

export const useSelf = () => {
  const history = useSummerHistory();

  const queryClient = useQueryClient();
  const result = useQuery({
    queryKey: [queryKey],
    queryFn: fetchSelf,
    retry: false,
    onError: err => {
      if (get(err, 'response.data.statusCode') === 401) {
        clearSelf(queryClient);
      }
      if (get(err, 'message') === 'Network Error') {
        history.push('/error?isVpn=true');
      }
    },
  });

  return { self: result.data, ...result };
};

export const logoutSelf = async queryClient => {
  await requestLogout();
  clearSelf(queryClient);
};
