/* eslint-disable react/display-name */
import { isEmpty, get, filter, isNil } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import styled from 'styled-components';

import {
  VirtualizedCardTable,
  Cell,
  Text,
  Icon,
  COLORS,
  Caret,
} from '@summer/ui-components';

import { Box, Flex } from 'components/box/Box';
import DateCell from 'components/cells/DateCell';
import Loading from 'components/placeholders/Loading';
import NoDataToDisplay from 'components/placeholders/NoDataToDisplay';
import { useSummerHistory } from 'hooks/useSummerHistory';
import { useForms } from 'state/server/forms';
import { UPDATED_BY_BORROWER } from 'utils/constants';
import { formSignedAgainRecently } from 'utils/forms';

const FormsTable = ({ filters }) => {
  const history = useSummerHistory();
  const containerRef = useRef();
  const [order, setOrder] = useState('DESC');
  const {
    forms,
    formsTotalCount,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingMore,
  } = useForms({ filters, order });

  const formsToDisplay = useMemo(() => {
    if (filters.clientSideFilter === UPDATED_BY_BORROWER) {
      return filter(forms, formSignedAgainRecently);
    }
    return forms;
  }, [forms, filters.clientSideFilter]);

  const columns = useMemo(() => {
    return [
      {
        Header: (
          <HeaderContainer iconFlipped={order === 'DESC'}>
            <Text weight="medium" size="extraSmall">
              Date last signed
            </Text>{' '}
            <Icon
              onClick={() => {
                setOrder(order === 'ASC' ? 'DESC' : 'ASC');
              }}
              fill={COLORS.azure}
              hoverFill={COLORS.skyBlue}
              SvgComponent={Caret}
              testId="sortIcon"
            />
          </HeaderContainer>
        ),
        accessor: 'signedAt',
        Cell: ({ cell }) => (
          <DateCell value={cell.value} formatString="MMM d yyyy" />
        ),
      },
      { Header: 'Form Type', accessor: 'formType' },
      { Header: 'Borrower ID', accessor: 'borrowerId' },
      { Header: 'First Name', accessor: 'firstName' },
      { Header: 'Last Name', accessor: 'lastName' },
      {
        Header: 'Status',
        accessor: 'metadata.status',
        Cell: ({ cell }) => <Cell type="string" value={cell.value} />,
      },
      {
        Header: 'Status Date',
        accessor: 'metadata.statusDate',
        Cell: ({ cell }) => (
          <DateCell value={cell.value} formatString="MMM d yyyy" />
        ),
      },
      {
        Header: 'Owner',
        accessor: 'metadata.owner.name',
        Cell: ({ cell }) => <Cell type="string" value={cell.value} />,
      },
    ];
  }, [order, setOrder]);

  const isItemLoaded = index => {
    return !hasNextPage || index < forms?.length - 1;
  };

  const loadMoreItems = () => {
    if (!isFetchingMore && !isLoading) {
      return fetchNextPage();
    }
    return null;
  };

  return (
    <Box as="section" my={32} flex="1" ref={containerRef}>
      <Box mb={2}>
        {isNil(filters.clientSideFilter) && (
          <Text align="right" weight="medium">
            Total Forms: {formsTotalCount}
          </Text>
        )}
      </Box>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center">
          <Loading />
        </Flex>
      )}
      {!isLoading &&
        (isEmpty(formsToDisplay) ? (
          <NoDataToDisplay />
        ) : (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={forms?.length}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={20}
          >
            {({ onItemsRendered, ref }) => (
              <VirtualizedCardTable
                columns={columns}
                data={formsToDisplay}
                align="left"
                getCustomRowProps={(props, options) => {
                  const { row } = options;
                  const formId = get(row, 'original.formId');

                  // combine row props With our custom row props
                  const style = { ...props.style };
                  return {
                    ...props,
                    style,
                    onClick: () => {
                      history.push(`/forms/${formId}`);
                    },
                  };
                }}
                virtualizedOptions={{
                  height: 690 - 62,
                  onItemsRendered,
                  ref,
                }}
              />
            )}
          </InfiniteLoader>
        ))}
    </Box>
  );
};

const HeaderContainer = styled.div`
  & > * {
    display: inline;
  }
  & svg {
    transform: rotate(${props => (props.iconFlipped ? '0deg' : '180deg')});
  }
`;

export default FormsTable;
