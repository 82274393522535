/* eslint-disable react/jsx-key */
import { isString, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTable, useFlexLayout, useSortBy } from 'react-table';
import { FixedSizeList } from 'react-window';
import styled, { css } from 'styled-components';

import { Text, SortZa, SortAz, Icon } from '@summer/ui-components';
import { hexToRgba } from '@summer/ui-components/src/helpers/utils/common';

import { Flex } from 'components/box/Box';
import { COLORS } from 'styles/styleguide';

const GUTTER_SIZE = 4;
const ITEM_SIZE = 84;

// get props the hide header cell if necessary
const getHiddenHeaderProps = (props, options) => {
  const hideHeader = options.column.hideHeader === true ? 'none' : null;
  const style = { ...props.style, display: hideHeader };
  return {
    ...props,
    style,
  };
};

const DefaultRowCellComponent = ({ cell }) => {
  return (
    <Text
      weight="medium"
      color="dark"
      size="small"
      truncate
      paragraph
      smallTracking
    >
      {cell.value}
    </Text>
  );
};

const SortableCardTable = ({
  columns,
  data,
  getCustomRowProps,
  tableIndex,
  tableName,
  colDefaultWidth = 100,
  align = 'center',
  fitToContent = false,
  style = null,
  horizontalScroll,
  virtualizedOptions,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      // minWidth: 30, // minWidth is only used as a limit for resizing
      // maxWidth: 200, // maxWidth is only used as a limit for resizing
      width: colDefaultWidth, // width is used for both the flex-basis and flex-grow
      Cell: DefaultRowCellComponent,
    }),
    [colDefaultWidth],
  );
  const {
    columns: instantColumns,
    headerGroups,
    rows,
    prepareRow,
    getTableProps,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      defaultColumn,
      defaultPageSize: 10,
    },
    useSortBy,
    useFlexLayout,
  );

  // handle column visibility
  React.useEffect(() => {
    instantColumns.forEach(col => {
      if (col.show === true) {
        col.toggleHidden(false);
      } else if (col.show === false) {
        col.toggleHidden(true);
      }
    });
  }, [instantColumns]);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      const rowId = `${tableName || 'table'}${tableIndex}-row${index}`;
      prepareRow(row);
      return (
        <Row
          {...row.getRowProps(getCustomRowProps)}
          style={{
            ...style,
            top: style.top + GUTTER_SIZE,
            height: style.height - GUTTER_SIZE,
            left: style.left + GUTTER_SIZE,
            width: `calc(${style.width} - ${GUTTER_SIZE * 2}px)`,
            ...row.getRowProps(getCustomRowProps)?.style,
          }}
          tabIndex="0"
          className="table-row"
          id={rowId}
        >
          {row.cells.map(cell => {
            return (
              <RowCell key={cell.value} {...cell.getCellProps()} align={align}>
                {cell.render('Cell')}
              </RowCell>
            );
          })}
        </Row>
      );
    },
    [prepareRow, rows, align, getCustomRowProps, tableIndex, tableName],
  );
  return (
    <Container
      className="card-table"
      style={style}
      horizontalScroll={horizontalScroll}
    >
      <Table {...getTableProps()} data-private fitToContent={fitToContent}>
        {headerGroups.map(headerGroup => (
          <HeaderContainer {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              return (
                <>
                  {column.sortable ? (
                    <SortableHeaderCell
                      {...column.getHeaderProps(getHiddenHeaderProps)}
                      align={align}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {isString(column.Header) ? (
                        <Text weight="medium" size="extraSmall">
                          {column.render('Header')}
                        </Text>
                      ) : (
                        column.render('Header')
                      )}
                      <SortIconContainer>
                        <SortIcon
                          fill="#6D7581"
                          SvgComponent={column.isSortedDesc ? SortZa : SortAz}
                        />
                      </SortIconContainer>
                    </SortableHeaderCell>
                  ) : (
                    <HeaderCell
                      {...column.getHeaderProps(getHiddenHeaderProps)}
                      align={align}
                    >
                      {isString(column.Header) ? (
                        <Text weight="medium" size="extraSmall">
                          {column.render('Header')}
                        </Text>
                      ) : (
                        column.render('Header')
                      )}
                    </HeaderCell>
                  )}
                </>
              );
            })}
          </HeaderContainer>
        ))}
        <BodyContainer>
          <BodyContainer>
            <FixedSizeList
              height={
                data.length < 4
                  ? data.length * ITEM_SIZE + 30
                  : get(virtualizedOptions, 'height', 0)
              }
              ref={get(virtualizedOptions, 'ref')}
              onItemsRendered={get(virtualizedOptions, 'onItemsRendered')}
              itemCount={rows.length}
              itemSize={ITEM_SIZE + GUTTER_SIZE}
            >
              {RenderRow}
            </FixedSizeList>
          </BodyContainer>
        </BodyContainer>
      </Table>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  overflow: scroll;
  min-width: ${({ horizontalScroll }) =>
    horizontalScroll ? '150vw' : 'unset'};
`;

const Table = styled.div`
  width: ${({ fitToContent }) => (fitToContent ? 'fit-content' : '100%')};
  min-width: unset !important;
  padding: 0px;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 16px 16px;
  align-items: center;
  border-radius: 3px;
  background-blend-mode: multiply;
  background-color: ${hexToRgba(COLORS.azure, 0.09)};
`;

const BodyContainer = styled.div`
  & > div {
    margin-bottom: 8px;
  }
`;

const rowInteractiveStyle = css`
  cursor: pointer;

  :hover {
    box-shadow: 0 2px 10px 2px rgba(214, 221, 231, 0.81);
    border: solid 1px ${COLORS.lighterGrey};
    background-color: ${COLORS.white};
  }

  :active {
    box-shadow: 0 2px 10px 2px rgba(214, 221, 231, 0.81);
    border: solid 1px ${COLORS.lighterGrey};
    background-color: ${COLORS.lightestGrey};
  }

  :focus {
    box-shadow: 0 0 5px 0 rgba(0, 166, 255, 0.5);
    border: solid 1px #41beff;
    outline: none;
  }
`;

const rowValidStyle = css`
  border-left: solid 6px ${COLORS.orange} !important;
  &.error-focus {
    box-shadow: 0 2px 10px 2px rgba(214, 221, 231, 0.81);
    border: solid 1px ${COLORS.lighterGrey};
    background-color: ${COLORS.white};
  }
`;

const Row = styled.div.attrs(props => ({
  tabIndex: props.onClick ? '0' : '-1',
}))`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  padding: 16px 16px;
  border-radius: 6px;
  border: solid 1px #ebeff4;
  background-color: ${COLORS.white};
  transition: all 0.3s ease;
  outline: none;
  font-size: 14px !important;

  :first-child {
    text-align: left;
  }

  ${({ onClick }) => onClick && rowInteractiveStyle}

  ${({ valid }) => valid === false && rowValidStyle}
`;

/*
 * The use of justify-content in this component it to support our current web-app HeaderCell component
 * when using HeaderCell component align will not work and without it tooltip will break
 *
 * Todo: update tooltip design and HeaderCell to support normal align
 * */
const HeaderCell = styled.div`
  flex-shrink: 1 !important;
  flex-flow: nowrap;
  overflow: hidden;
  padding: 8px 8px;
  text-align: ${({ align }) => align};

  > * {
    justify-content: center;
  }

  :first-child {
    text-align: left;

    > * {
      justify-content: flex-start;
    }
  }
`;

const SortableHeaderCell = styled.div`
  flex-shrink: 1 !important;
  flex-flow: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  display: flex;
  text-align: ${({ align }) => align};
`;

const RowCell = styled.div`
  flex-flow: row nowrap;
  flex-shrink: 1 !important;
  flex-basis: auto;
  overflow: hidden;
  padding: 8px 8px;
  text-align: ${({ align }) => align};

  :first-child {
    text-align: left;
  }
  font-size: 14px !important;
`;

const SortIcon = styled(Icon)`
  cursor: pointer;
`;

const SortIconContainer = styled(Flex)`
  cursor: pointer;
  width: 21px;
  height: 21px;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  &:hover {
    mix-blend-mode: multiply;
    border-radius: 3px;
    background: ${COLORS.blueGrey};
  }
`;

SortableCardTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      id: PropTypes.string,
      columns: PropTypes.array,
      Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.elementType,
      ]),
      Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
      width: PropTypes.number,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
    }),
  ).isRequired,
  getRowProps: PropTypes.func,
  tableIndex: PropTypes.number,
  colDefaultWidth: PropTypes.number,
  fitToContent: PropTypes.bool,
  align: PropTypes.PropTypes.oneOf(['center', 'left', 'right']),
};

SortableCardTable.defaultProps = {
  tableIndex: 0,
  colDefaultWidth: 100,
  align: 'center',
};

export default SortableCardTable;
