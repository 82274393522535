import axios from 'axios';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Header, Icon, SummerLogo } from '@summer/ui-components';

import OAuthButton from 'components/auth/OAuthButton';
import { Flex } from 'components/box/Box';
import { setAdminUserForErrorTracking } from 'errorTracking';
import { setSelf } from 'state/server/self';
import { getThemeColor } from 'utils/theme-helpers';

const Login = ({ adminUser }) => {
  const queryClient = useQueryClient();
  const host = process.env.REACT_APP_API_BASE_URL;
  const { search } = window.location;

  useEffect(() => {
    (async function createOrFetchUser() {
      if (!adminUser && search) {
        const redirectLink = `${window.location.protocol}//${window.location.host}/login`;

        const res = await axios({
          method: 'post',
          url: `${host}/v1/oauth/google${search}&clientRedirect=true&redirectTo=${redirectLink}`,
          withCredentials: true,
        });

        const { data: adminUserFromRes } = res.data;

        setSelf(queryClient, adminUserFromRes);
        setAdminUserForErrorTracking({
          email: adminUserFromRes?.email,
          id: adminUserFromRes?.uuid,
        });
      }
    })();
  }, [host, search, adminUser, queryClient]);

  const handleClick = () => {
    const link = `${host}/v1/oauth/google?redirectTo=${window.location.href}`;

    window.location.href = link;
  };

  return (
    <Flex width={1} height="100%" flexDirection="column">
      <LogoHeader
        width={1}
        p={24}
        height={80}
        alignItems="center"
        flexDirection="row"
      >
        <Logo width={140} height={35} SvgComponent={SummerLogo} />
      </LogoHeader>
      <Body p="12%">
        <LoginContainer
          flexDirection="column"
          width={543}
          height={240}
          py={48}
          px={56}
          borderRadius={6}
          alignItems="center"
        >
          <Header>Welcome to Summer Admin</Header>
          <ButtonContainer id="loginButton">
            <OAuthButton onClick={handleClick} />
          </ButtonContainer>
        </LoginContainer>
      </Body>
    </Flex>
  );
};

const LogoHeader = styled(Flex)`
  justify-content: space-between;

  & > * {
    width: 200px; // to center items
  }
  .icon * {
    fill: ${getThemeColor('azure')};
  }
`;

const Logo = styled(Icon)`
  width: 140px;
  height: 35px;
`;

const Body = styled(Flex)`
  justify-content: center;
`;

const LoginContainer = styled(Flex)`
  border: solid 1px ${getThemeColor('lighterGrey')};
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

export default Login;
