import React from 'react';

import { Text } from '@summer/ui-components/src/core/typography';
import Header from '@summer/ui-components/src/core/typography/Header';

import { Box, Flex } from 'components/box/Box';
import { useStateFromUrl } from 'hooks/urlState';
import BorrowersFilters from 'views/Users/BorrowersFilter';
import BorrowersTable from 'views/Users/BorrowersTable';

const BorrowersView = () => {
  const [filters, updateFilters] = useStateFromUrl();

  return (
    <Flex flexDirection="column" height="100%">
      <Header h1>Borrowers Search</Header>
      <Box
        as="section"
        bg="lighterGrey"
        height={160}
        mx={-48}
        px={48}
        mt={24}
        mb={24}
      >
        <BorrowersFilters updateFilters={updateFilters} filters={filters} />
      </Box>
      <Text bold>Results</Text>
      <BorrowersTable filters={filters} />
    </Flex>
  );
};

export default BorrowersView;
