import { map, filter } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import DownloadFile, {
  DownloadFileDisabled,
} from 'components/buttons/DownloadFile';
import { useFormDownloadURL } from 'state/server/forms';

const displayName = {
  idr: 'IDR',
  pslf: 'PSLF',
  consolidation: 'Consolidation',
};

const downloadReadyStatuses = [
  FormStatus.SIGNED,
  FormStatus.PROCESSED,
  FormStatus.COMPLETED,
];

/**
 * A form download link.
 *
 * @param {{
 *  formType: keyof displayName;
 *  formId: string;
 *  documentId: string;
 * }} params
 */
const DownloadLink = ({ formType, formId, documentId }) => {
  const { fileUrl, isLoading } = useFormDownloadURL({ formId, documentId });

  const isHrefValid = typeof fileUrl === 'string' && fileUrl.length > 0;
  const name = displayName[formType];

  if (!isHrefValid) {
    return <DownloadFileDisabled displayName={name} />;
  }

  return (
    <DownloadFile isLoading={isLoading} href={fileUrl} displayName={name} />
  );
};

const FormDownload = ({ form }) => {
  const { signRequests, id: formId, formType, status: formStatus } = form;

  const completedBorrowerSignRequests = filter(signRequests, {
    complete: true,
    role: 'borrower',
  });

  const formIsReadyToDownload =
    downloadReadyStatuses.includes(formStatus) &&
    signRequests.every(sr => sr.documentId);

  return (
    <FormDownloadContainer>
      {formIsReadyToDownload ? (
        map(completedBorrowerSignRequests, ({ formType, documentId }) => (
          <DownloadLink
            key={documentId}
            formType={formType}
            formId={formId}
            documentId={documentId}
          />
        ))
      ) : (
        <DownloadFileDisabled displayName={displayName[formType]} />
      )}
    </FormDownloadContainer>
  );
};

const FormDownloadContainer = styled.div`
  text-align: right;

  .text {
    display: block;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }
`;

export default FormDownload;
