import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Header } from '@summer/ui-components';

import { Flex } from 'components/box/Box';
import { welcomes } from 'utils/welcome';

const BaseView = ({ adminUser }) => {
  const [welcomeIndex, setWelcomeIndex] = useState(0);

  useEffect(() => {
    const counter = () => {
      const randomIndex = Math.floor(Math.random() * welcomes.length);
      setWelcomeIndex(randomIndex);
    };

    const id = setInterval(counter, 1000);
    return () => clearInterval(id);
  }, [welcomeIndex]);

  return (
    <Flex
      flexDirection="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Header>
        {capitalize(welcomes[welcomeIndex]?.text)} {adminUser.firstName}!
      </Header>
      <img alt="" src="https://media.giphy.com/media/qPCln5TSOsdRS/giphy.gif" />
    </Flex>
  );
};

export default BaseView;
