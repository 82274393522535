import { get, join, startCase } from 'lodash';
import React from 'react';

import { DataDisplay } from '@summer/ui-components';

import { Box } from 'components/box/Box';
import { isIdr, isPslf } from 'utils/constants';
import { calcDuration } from 'utils/forms';

const idrData = ({
  form: { currentPath, selectedPath, targetServicerName, servicersToSendIDR },
  borrower: { isPublicSector },
}) => {
  return [
    {
      title: '',
      data: [
        [
          {
            label: 'Current Plan',
            value: join(currentPath?.planType, ' and '),
          },
          { label: 'New Plan', value: join(selectedPath?.planType, ' and ') },
          { label: 'Servicers', value: join(servicersToSendIDR, ', ') },
          {
            label: 'Servicer for Consolidation',
            value: targetServicerName || '-',
          },
          { label: 'App Type', value: get(selectedPath, 'appType') || '-' },
        ],
        [
          {
            label: 'Works in Public Sector?',
            value: startCase(isPublicSector),
          },
        ],
      ],
    },
  ];
};

const pslfData = ({
  employerName,
  employerType,
  employerStartDate,
  employerEndDate,
  employerHoursPerWeek,
  hrEmail,
  hrFirstName,
  hrLastName,
}) => {
  const hrFullName = `${hrFirstName} ${hrLastName}`;

  return [
    {
      title: '',
      data: [
        [
          { label: 'Employer', value: employerName },
          { label: 'Employer Type', value: employerType },
          {
            label: 'Duration',
            value: calcDuration(employerStartDate, employerEndDate),
          },
        ],
        [
          { label: 'HR Contact', value: hrFullName },
          { label: 'HR Contact Email', value: hrEmail },
          { label: 'Hours/wk', value: employerHoursPerWeek },
        ],
      ],
    },
  ];
};

const FormData = ({ form }) => {
  let formDataToDisplay;

  if (isIdr(form.formType)) {
    formDataToDisplay = idrData(form);
  } else if (isPslf(form.formType)) {
    formDataToDisplay = pslfData(form?.form);
  }

  return (
    <Box
      border="solid 1px"
      borderRadius={6}
      borderColor="blueGrey"
      px={24}
      pt={24}
    >
      <DataDisplay data={formDataToDisplay} />
    </Box>
  );
};

export default FormData;
