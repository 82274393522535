import { isNil } from 'lodash';
import React, { useMemo } from 'react';

import {
  borrowerHighInfoData,
  borrowerOnboardingLoanInfoData,
  borrowerOnboardingInfoData,
  borrowerTuayInfo,
  borrowerUnemploymentInfo,
  borrowerSpouseInfo,
  borrowerSpouseUnemploymentInfo,
  borrowerPaidLoanDetailData,
  borrowerLoanDetailData,
  borrowerLoanSummaryData,
  borrowerIDRFormSummaryData,
  borrowerPSLFFormSummaryData,
  borrowerRecommendPlansData,
} from 'utils/borrower';
import {
  LOAN_TYPE_FSA,
  LOAN_TYPE_PLAID_FEDERAL,
  LOAN_TYPE_PLAID_PRIVATE,
} from 'utils/constants';
import BorrowerHighInfoDisplay from 'views/Borrower/BorrowerHighInfo';
import BorrowerIDRFormSummaryTable from 'views/Borrower/BorrowerIDRFormSummaryTable';
import BorrowerIDRInfoDisplay from 'views/Borrower/BorrowerIDRInfo';
import BorrowerLoanDetailsTable from 'views/Borrower/BorrowerLoanDetailsTable';
import BorrowerLoanSummaryDisplay from 'views/Borrower/BorrowerLoanSummary';
import BorrowerOnboardingInfoDisplay from 'views/Borrower/BorrowerOnboardingInfo';
import BorrowerPSLFFormSummaryTable from 'views/Borrower/BorrowerPSLFFormSummaryTable';
import BorrowerRecommendedPlansDisplay from 'views/Borrower/BorrowerRecommendPlans';

const BorrowerData = ({ borrower }) => {
  const memoizedHighInfoData = useMemo(
    () => borrowerHighInfoData({ borrower }),
    [borrower],
  );
  const memoizedOnboardingLoanInfo = useMemo(
    () => borrowerOnboardingLoanInfoData({ borrower }),
    [borrower],
  );

  const memoizedOnboardingBorrowerInfo = useMemo(
    () => borrowerOnboardingInfoData({ borrower }),
    [borrower],
  );
  const memoizedFSALoanSummaryData = useMemo(
    () => borrowerLoanSummaryData({ borrower }, LOAN_TYPE_FSA),
    [borrower],
  );
  const memoizedFSAPaidLoanInfo = useMemo(
    () => borrowerPaidLoanDetailData({ borrower }, LOAN_TYPE_FSA),
    [borrower],
  );
  const memoizedFSALoanInfo = useMemo(
    () => borrowerLoanDetailData({ borrower }, LOAN_TYPE_FSA),
    [borrower],
  );
  const memoizedPlaidFederalLoanSummaryData = useMemo(
    () => borrowerLoanSummaryData({ borrower }, LOAN_TYPE_PLAID_FEDERAL),
    [borrower],
  );
  const memoizedPlaidPrivateLoanSummaryData = useMemo(
    () => borrowerLoanSummaryData({ borrower }, LOAN_TYPE_PLAID_PRIVATE),
    [borrower],
  );
  const memoizedPlaidFederalPaidLoanInfo = useMemo(
    () => borrowerPaidLoanDetailData({ borrower }, LOAN_TYPE_PLAID_FEDERAL),
    [borrower],
  );
  const memoizedPlaidPrivatePaidLoanInfo = useMemo(
    () => borrowerPaidLoanDetailData({ borrower }, LOAN_TYPE_PLAID_PRIVATE),
    [borrower],
  );
  const memoizedPlaidFederalLoanInfo = useMemo(
    () => borrowerLoanDetailData({ borrower }, LOAN_TYPE_PLAID_FEDERAL),
    [borrower],
  );

  const memoizedPlaidPrivateLoanInfo = useMemo(
    () => borrowerLoanDetailData({ borrower }, LOAN_TYPE_PLAID_PRIVATE),
    [borrower],
  );

  const memoizedBorrowerTuayInfo = useMemo(
    () => !isNil(borrower.financialProfile) && borrowerTuayInfo({ borrower }),
    [borrower],
  );

  const memoizedBorrowerUnemploymentInfo = useMemo(
    () =>
      !isNil(borrower.financialProfile) &&
      borrowerUnemploymentInfo({ borrower }),
    [borrower],
  );
  const memoizedBorrowerSpouseInfo = useMemo(
    () => !isNil(borrower.financialProfile) && borrowerSpouseInfo({ borrower }),
    [borrower],
  );

  const memoizedSpouseUnemploymentInfo = useMemo(
    () =>
      !isNil(borrower.financialProfile) &&
      borrowerSpouseUnemploymentInfo({ borrower }),
    [borrower],
  );

  const memoizedBorrowerHasFinancialProfile = useMemo(() => {
    return borrower.financialProfile;
  }, [borrower]);
  const memoizedBorrowerIDRForms = useMemo(
    () => borrowerIDRFormSummaryData({ borrower }),
    [borrower],
  );
  const memoizedBorrowerPSLFForms = useMemo(
    () => borrowerPSLFFormSummaryData({ borrower }),
    [borrower],
  );

  const memoizedBorrowerRecommendedPlansData = useMemo(() => {
    return borrowerRecommendPlansData({ borrower });
  }, [borrower]);

  return (
    <>
      <BorrowerHighInfoDisplay
        borrower={borrower}
        data={memoizedHighInfoData}
      />

      <BorrowerOnboardingInfoDisplay
        loanInfo={memoizedOnboardingLoanInfo}
        borrowerInfo={memoizedOnboardingBorrowerInfo}
      />
      <BorrowerLoanSummaryDisplay
        borrowerInfo={memoizedFSALoanSummaryData}
        type={LOAN_TYPE_FSA}
      />
      <BorrowerLoanDetailsTable
        paidLoanInfo={memoizedFSAPaidLoanInfo}
        activeloanInfo={memoizedFSALoanInfo}
        type={LOAN_TYPE_FSA}
      />
      <BorrowerLoanSummaryDisplay
        borrowerInfo={memoizedPlaidFederalLoanSummaryData}
        type={LOAN_TYPE_PLAID_FEDERAL}
      />
      <BorrowerLoanDetailsTable
        paidLoanInfo={memoizedPlaidFederalPaidLoanInfo}
        activeloanInfo={memoizedPlaidFederalLoanInfo}
        type={LOAN_TYPE_PLAID_FEDERAL}
      />
      <BorrowerLoanSummaryDisplay
        borrowerInfo={memoizedPlaidPrivateLoanSummaryData}
        type={LOAN_TYPE_PLAID_PRIVATE}
      />

      <BorrowerLoanDetailsTable
        paidLoanInfo={memoizedPlaidPrivatePaidLoanInfo}
        activeloanInfo={memoizedPlaidPrivateLoanInfo}
        type={LOAN_TYPE_PLAID_PRIVATE}
      />
      <BorrowerIDRInfoDisplay
        borrowerHasFinancialProfile={memoizedBorrowerHasFinancialProfile}
        tuayInfo={memoizedBorrowerTuayInfo}
        unemploymentInfo={memoizedBorrowerUnemploymentInfo}
        spouseInfo={memoizedBorrowerSpouseInfo}
        spouseUnemploymentInfo={memoizedSpouseUnemploymentInfo}
      />
      <BorrowerRecommendedPlansDisplay
        recommendedPlans={memoizedBorrowerRecommendedPlansData}
      />
      {memoizedBorrowerIDRForms.length > 0 && (
        <BorrowerIDRFormSummaryTable forms={memoizedBorrowerIDRForms} />
      )}
      {memoizedBorrowerPSLFForms.length > 0 && (
        <BorrowerPSLFFormSummaryTable forms={memoizedBorrowerPSLFForms} />
      )}
    </>
  );
};

export default BorrowerData;
