import { format as formatCurrency } from 'currency-formatter';
import { map, sum, get, isNil } from 'lodash';

export const arrayToOptions = data => {
  return map(data, item => ({
    label: item,
    value: item,
  }));
};

export const convertCentsToDollar = num => {
  return num / 100;
};

export const dollars = (amount, { precision = 0, showSymbol = true } = {}) => {
  if (typeof amount !== 'number') {
    return '';
  }
  const formattedDollars =
    amount || precision
      ? formatCurrency(amount / 100, { code: 'USD', precision })
      : '$0';
  return showSymbol ? formattedDollars : formattedDollars.slice(1);
};

export const rateParser = (
  amount,
  { precision = 2, showSymbol = true } = {},
) => {
  if (typeof amount !== 'number') {
    return amount;
  }
  const formattedPercentage = amount
    ? (amount * 100).toFixed(precision).toString()
    : '0';
  return showSymbol ? `${formattedPercentage}%` : formattedPercentage;
};

export const yesOrNo = item => {
  return item ? 'Yes' : 'No';
};

export const avgInterestRate = loans => {
  const activeLoans = loans.filter(item => item.principalBalance !== 0);

  const isMissingDataForAvgInterestRate = !!activeLoans.find(
    loan => isNil(loan.interestRate) || isNil(loan.principalBalance),
  );
  if (isMissingDataForAvgInterestRate) {
    return '-';
  }

  const weightedBalance = sum(
    activeLoans.map(item => item.principalBalance * item.interestRate),
  );

  const totalBalance = sum(activeLoans.map(item => item.principalBalance));

  const weightedAverageInterestRate = weightedBalance / totalBalance;

  return weightedAverageInterestRate;
};

export const isCaresActEligible = (federalLoanTypes, servicerNames) => {
  if (get(federalLoanTypes, 'isPerkins')) {
    return 'No';
  }
  if (
    get(federalLoanTypes, 'isFfel') &&
    !servicerNames?.some(name => name?.includes('DEPT OF ED'))
  ) {
    return 'No';
  }
  if (!federalLoanTypes || !servicerNames) {
    return 'unknown';
  }

  return 'Yes';
};

export const formatPhone = phoneNumber =>
  !isNil(phoneNumber)
    ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3')
    : '–';

export const getIsSplittable = string => {
  if (Array.isArray(string)) {
    return string.length > 1;
  }
  if (string?.split(' ')) {
    return string.split(' ').length > 1;
  }
  return false;
};
