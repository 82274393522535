import { get, forEach, last } from 'lodash';
import { useMemo } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { api } from 'services/api';

/**
 *
 * Api requests for React-Query
 *
 */

const fetchForms = async ({ filters, order, config, pageParam }) => {
  const { borrowerId } = filters;
  const result = await api.get('/forms', {
    params: {
      borrower_id: borrowerId === '' ? null : borrowerId,
      status: get(filters, 'status'),
      owner_uuid: get(filters, 'ownerUuid'),
      partner_id: get(filters, 'partner'),
      form_type: get(filters, 'formType'),
      fax_submission_status: get(filters, 'faxSubmissionStatus'),
      limit: get(config, 'limit', 20),
      offset: pageParam ?? 0,
      order,
    },
  });

  return {
    forms: result?.data.data,
    nextOffset: result?.data.nextOffset,
    count: result?.data.count,
  };
};

const fetchForm = async ({ formId }) => {
  const formRes = await api.get(`/forms/${formId}`);

  return formRes?.data.data;
};

const putFormMetadata = async ({ formId, payload }) => {
  const formRes = await api.put(`/forms/${formId}/metadata`, payload);

  return formRes?.data.data;
};

const putFormStatusAsSigned = async ({ formId }) => {
  const formRes = await api.put(`/forms/${formId}/status`);

  return formRes?.data.data;
};

const postFormDownloadURL = async ({ formId, documentId }) => {
  const fileUrlRes = await api.post(`/forms/${formId}/${documentId}`);

  return fileUrlRes?.data.data;
};

/**
 *
 * Custom Hooks with React-Query
 *
 */

export const useForms = ({
  filters = {},
  order,
  config = { limit: 20 },
} = {}) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['forms', filters, order, config],
      queryFn: ({ pageParam }) =>
        fetchForms({ filters, order, config, pageParam }),
      getNextPageParam: lastPage => {
        return lastPage.nextOffset ?? undefined;
      },
      refetchOnWindowFocus: false,
    });

  const resultArray = data?.pages;

  const forms = useMemo(() => {
    const formsTemp = [];

    forEach(resultArray, result => {
      formsTemp.push(...result?.forms);
    });

    return formsTemp;
  }, [resultArray]);

  const formsTotalCount = last(resultArray)?.count || 0;

  return {
    forms,
    formsTotalCount,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export const useForm = ({ formId }) => {
  const { data: form, isLoading } = useQuery({
    queryKey: ['form', `${formId}`],
    queryFn: () => fetchForm({ formId }),
  });

  return { form, isLoading };
};

export const useMutateForm = ({ fromOverlay = false } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['putFormMetadata'],
    mutationFn: putFormMetadata,
    onSuccess: (_, { formId }) => {
      // formId need to be string because when we create the form query we use formId from query params
      queryClient.invalidateQueries(
        fromOverlay ? ['form'] : ['form', `${formId}`],
      );
    },
  });
};

export const useMutateFormSigned = () => {
  return useMutation({
    mutationKey: ['putFormStatusAsSigned'],
    mutationFn: putFormStatusAsSigned,
  });
};

export const useFormDownloadURL = ({ formId, documentId }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['form', formId, documentId],
    queryFn: () => postFormDownloadURL({ formId, documentId }),
  });

  return { fileUrl: data?.fileUrl, isLoading };
};
