/* eslint-disable react/display-name */
import { isEmpty, get } from 'lodash';
import React, { useMemo, useRef } from 'react';

import { CardTable, Cell } from '@summer/ui-components';

import { Box, Flex } from 'components/box/Box';
import DateCell from 'components/cells/DateCell';
import Loading from 'components/placeholders/Loading';
import NoDataToDisplay from 'components/placeholders/NoDataToDisplay';
import { useSummerHistory } from 'hooks/useSummerHistory';
import { useBorrowers } from 'state/server/borrowers';

const BorrowersTable = ({ filters }) => {
  const history = useSummerHistory();
  const containerRef = useRef();
  const { borrowers, isLoading } = useBorrowers({ filters });

  const borrowersToDisplay = useMemo(() => {
    return filters && Object?.keys(filters)?.some(key => filters[key] != null)
      ? borrowers
      : [];
  }, [borrowers, filters]);

  const columns = useMemo(() => {
    return [
      { Header: 'Borrower ID', accessor: 'id' },
      { Header: 'First Name', accessor: 'firstName' },
      { Header: 'Last Name', accessor: 'lastName' },
      { Header: 'Email', accessor: 'email' },
      {
        Header: 'Account Created',
        accessor: 'createdAt',
        Cell: ({ cell }) => (
          <DateCell value={cell.value} formatString="MMM d yyyy" />
        ),
      },
      {
        Header: 'Partner',
        accessor: 'partnerName',
        Cell: ({ cell }) => <Cell type="string" value={cell.value} />,
      },
    ];
  }, []);

  return (
    <Box as="section" mt={32} mb={32} flex="1" ref={containerRef}>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center">
          <Loading />
        </Flex>
      )}
      {!isLoading &&
        (isEmpty(borrowersToDisplay) ? (
          <NoDataToDisplay />
        ) : (
          <CardTable
            columns={columns}
            data={borrowersToDisplay}
            align="left"
            style={{ overflow: 'hidden' }}
            getCustomRowProps={(props, options) => {
              const { row } = options;
              const borrowerId = get(row, 'original.id');

              // combine row props With our custom row props
              const style = { ...props.style };
              return {
                ...props,
                style,
                onClick: () => {
                  history.push(`/borrowers/${borrowerId}`);
                },
              };
            }}
            virtualizedOptions={{
              height: 690 - 62,
            }}
          />
        ))}
    </Box>
  );
};

export default BorrowersTable;
