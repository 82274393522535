import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, ModalPopup } from '@summer/ui-components/src';

import ConfirmationModal from 'views/Form/AutoFax/ConfirmationModal';

const ConfirmationModalButton = ({
  formId,
  formType,
  faxNeedsRetry,
  disableOpenModalButton,
  setNewFaxSubmission,
}) => {
  const [isConfirmationPopupOpen, setIsModalPopupOpen] = useState(false);
  const toggleConfirmationPopup = () =>
    setIsModalPopupOpen(!isConfirmationPopupOpen);

  const openModalButtonText = faxNeedsRetry
    ? 'Retry fax'
    : `Fax ${formType.toUpperCase()} form`;

  return (
    <>
      <ModalPopup
        bodyPadding="48px"
        isOpen={isConfirmationPopupOpen}
        togglePopup={toggleConfirmationPopup}
        component={
          <ConfirmationModal
            formId={formId}
            setIsModalPopupOpen={setIsModalPopupOpen}
            setNewFaxSubmission={setNewFaxSubmission}
          />
        }
      />
      <FaxModalButton
        onClick={toggleConfirmationPopup}
        disabled={disableOpenModalButton}
      >
        {openModalButtonText}
      </FaxModalButton>
    </>
  );
};

const FaxModalButton = styled(Button)`
  width: 100%;
`;

export default ConfirmationModalButton;
