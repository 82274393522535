import { useEffect } from 'react';

const useClickOutside = (showOptions, toggleOptions, ref) => {
  useEffect(
    function clickOutside() {
      // Use the effect to close the dropdown if admin user clicks outside.
      const handleClickOutside = e => {
        if (ref.current?.contains(e.target)) {
          return;
        }
        if (showOptions) {
          toggleOptions();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    [showOptions, toggleOptions, ref],
  );
};

export default useClickOutside;
