import React from 'react';
import styled from 'styled-components';

import { Header, ModalPopup, Button } from '@summer/ui-components';

import { MobileSize } from 'styles/styleguide';

const ChangeStatusModal = props => {
  const { togglePopup, isPopupOpen, onConfirm } = props;

  return (
    <ModalPopup
      {...props}
      togglePopup={togglePopup}
      isOpen={isPopupOpen}
      kind="Update Status"
      fullHeight="small"
      width={700}
      bodyPadding="72px 32px 40px"
      closeSize={20}
      component={
        <>
          <HeaderContainer>
            <Header center>Are you sure you want to update status?</Header>
          </HeaderContainer>

          <ConfirmContainer>
            <Button onClick={onConfirm} width={260}>
              Yes, Update Status
            </Button>
          </ConfirmContainer>
        </>
      }
    />
  );
};

const HeaderContainer = styled.div`
  padding: 0 104px;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    padding: 0;
  }
`;

const ConfirmContainer = styled.div`
  margin-top: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${MobileSize}) {
    margin-right: 0;
    justify-content: center;
  }
`;

export default ChangeStatusModal;
