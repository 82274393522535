import React, { useState } from 'react';
import styled from 'styled-components';

import { TextInput, Button, Close, Icon } from '@summer/ui-components';

import { useMutateFormSigned } from 'state/server/forms';

const FormsMarkDialog = ({ open, setOpen }) => {
  const [formId, setFormID] = useState('');
  const [errorMessage, setError] = useState(false);

  const mutateSigned = useMutateFormSigned();

  const handleOnSubmit = formId => {
    mutateSigned.mutate(
      {
        formId,
      },
      {
        onSuccess: () => setOpen(false),
        onError: err => setError(err.response.data.message),
      },
    );
  };

  return (
    <DialogContainer open={open}>
      <DialogContent>
        <CloseIcon
          onClick={() => setOpen(false)}
          width={20}
          height={20}
          SvgComponent={Close}
          fill="#1d1d1d"
        />
        <TextInput
          noFormik
          touched={true}
          error={errorMessage}
          name="formID"
          label="Form ID"
          placeholder="Enter Form ID"
          value={formId}
          onChange={e => setFormID(e.target.value)}
        />
        <Button
          loading={mutateSigned.isLoading}
          onClick={() => handleOnSubmit(formId)}
        >
          {'Submit'}
        </Button>
      </DialogContent>
    </DialogContainer>
  );
};

export default FormsMarkDialog;

const DialogContainer = styled.div`
  display: ${props => (props.open ? 'flex' : 'none')};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;
const DialogContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  position: relative;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CloseIcon = styled(Icon)`
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
`;
