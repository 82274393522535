import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Icon, Text } from '@summer/ui-components';

import { ReactComponent as WeatherCloud } from 'assets/icons/weather-cloud.svg';
import { Box, Flex } from 'components/box/Box';

const NoDataToDisplay = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <Flex
      bg="lighterGrey"
      height={560}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box mb={16}>
        <Icon SvgComponent={WeatherCloud} fill={themeContext.colors.medGrey} />
      </Box>
      <Text bold size="large" color="secondary">
        No data to display
      </Text>
    </Flex>
  );
};

export default NoDataToDisplay;
