import React, { useState } from 'react';

import Header from '@summer/ui-components/src/core/typography/Header';

import { Box, Flex } from 'components/box/Box';
import { useStateFromUrl } from 'hooks/urlState';
import FormsFilters from 'views/Forms/FormsFilters';
import FormsMarkDialog from 'views/Forms/FormsMarkDialog';
import FormsTable from 'views/Forms/FormsTable';

const FormsView = () => {
  const [filters, updateFilters] = useStateFromUrl({
    ownerUuid: null,
    borrowerId: '',
    status: null,
    partner: null,
    formType: null,
    clientSideFilter: null,
    faxSubmissionStatus: null,
  });

  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <Flex flexDirection="column" height="100%">
      <Header h1>Forms Received</Header>
      <Box
        as="section"
        bg="lighterGrey"
        mx={-48}
        px={48}
        mt={24}
        mb={24}
        pt={24}
      >
        <FormsFilters
          updateFilters={updateFilters}
          filters={filters}
          setDialogOpen={val => setDialogOpen(val)}
        />
      </Box>
      <FormsTable filters={filters} />
      {isDialogOpen && (
        <FormsMarkDialog
          open={isDialogOpen}
          setOpen={val => setDialogOpen(val)}
        />
      )}
    </Flex>
  );
};

export default FormsView;
