import { isAfter, format, parseISO } from 'date-fns';
import { isNil } from 'lodash';

export const formSignedAgainRecently = form => {
  const adminStatusDate = form.metadata?.statusDate;
  const productSignedDate = form.signedAt;
  const borrowerRecentlyUpdated =
    adminStatusDate &&
    productSignedDate &&
    isAfter(new Date(productSignedDate), new Date(adminStatusDate));

  return borrowerRecentlyUpdated;
};

export const formatStartDate = employerStartDate =>
  !isNil(employerStartDate)
    ? format(parseISO(employerStartDate), 'LLL yyyy')
    : 'Missing Start Date';
export const formatEndDate = employerEndDate =>
  !isNil(employerEndDate)
    ? format(parseISO(employerEndDate), 'LLL yyyy')
    : 'Present';
export const calcDuration = (employerStartDate, employerEndDate) =>
  isNil(employerStartDate) && isNil(employerEndDate)
    ? '-'
    : `${formatStartDate(employerStartDate)}-${formatEndDate(employerEndDate)}`;

export const signatureStatus = signRequests => {
  let status = '-';

  signRequests.forEach(req => {
    if (req?.role === 'employer' && req.complete) {
      status = 'All Signed';
    }
  });

  return status;
};
