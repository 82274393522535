import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { Icon, ModalPopup, Strong, Text } from '@summer/ui-components';

import { ReactComponent as SettingsIcon } from 'assets/icons/settings-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { Box, Flex } from 'components/box/Box';
import useClickOutside from 'hooks/useClickOutside';
import { useSummerHistory } from 'hooks/useSummerHistory';
import { useMutateBorrower } from 'state/server/borrowers';
import { useSelf } from 'state/server/self';
import { COLORS } from 'styles/styleguide';
import { getThemeColor } from 'utils/theme-helpers';

const BorrowerAdminPanel = ({ borrower }) => {
  const { self } = useSelf();
  const iconRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions, setShowOptions]);
  useClickOutside(showOptions, toggleOptions, iconRef);

  const [showDeleteBorrower, setShowOpToggleDeleteBorrower] = useState(false);
  const toggleDeleteBorrower = () =>
    setShowOpToggleDeleteBorrower(!showDeleteBorrower);

  if (self.role !== 'admin') {
    return null;
  }

  return (
    <>
      <Box display="inline-block" ml={2} ref={iconRef} position="relative">
        <Icon
          onClick={toggleOptions}
          SvgComponent={SettingsIcon}
          width={30}
          height={30}
        />
        {showOptions && (
          <Options bg="white" position="absolute" top={40}>
            <Option
              alignItems="flex-end"
              onClick={toggleDeleteBorrower}
              px={12}
              py={10}
            >
              <Icon
                onClick={toggleOptions}
                SvgComponent={TrashIcon}
                width={22}
                height={22}
              />
              <Text bold>Delete Borrower</Text>
            </Option>
          </Options>
        )}
      </Box>

      <DeleteBorrower
        borrower={borrower}
        isOpen={showDeleteBorrower}
        togglePopup={toggleDeleteBorrower}
      />
    </>
  );
};

const DeleteBorrower = ({ isOpen, togglePopup, borrower }) => {
  const { mutate } = useMutateBorrower('delete');
  const { goBack } = useSummerHistory();
  const handleDeleteBorrower = async () => {
    try {
      await mutate({ borrowerId: borrower.id });
      // eslint-disable-next-line no-alert
      alert(`Successfully deleted borrower ${borrower.id} `);
      goBack('/borrowers');
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(`An error occurred while deleting the borrower: ${e}`);
    }
  };

  return (
    <ModalPopup
      togglePopup={togglePopup}
      isOpen={isOpen}
      handleButtonClick={handleDeleteBorrower}
      buttonText="Yes, delete this borrower"
      component={() => (
        <Box>
          <Text paragraph>
            You are about to delete this borrower
            <br />
            <Strong>Id:</Strong>
            {borrower.id}
            <br />
            <Strong>Email:</Strong>
            {borrower.email}
          </Text>
          <Text paragraph bold color="error">
            This action will delete all data of the borrower permanently
          </Text>
        </Box>
      )}
    />
  );
};

const Options = styled(Box)`
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(195, 195, 195, 0.5);
  border: solid 1px ${getThemeColor('grey')};
  width: max-content;
`;

const Option = styled(Flex)`
  cursor: pointer;

  .icon {
    margin-right: 8px;
  }

  :hover {
    background-color: ${COLORS.darkerGrey};
    .text {
      color: ${COLORS.white};
      user-select: none;
    }
    .icon path,g {
        fill: white !important;
      }
    }
  }
`;

export default BorrowerAdminPanel;
