import { isEmpty } from 'lodash';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import { isIdr, isPslf } from 'utils/constants';

export const supportedServicerCompanyNames = [
  'AES',
  'AidVantage',
  'CornerStone',
  'Edfinancial/HESC',
  'Granite State - GSMR',
  'Great Lakes',
  'MOHELA',
  'Navient',
  'Nelnet',
  'OSLA Servicing',
  'PHEAA',
  'Test Fax Servicer',
];

const faxSubmissionStatus = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};

// server errors
export const PAGE_LIMIT_ERROR = 'Document exceeds max page limit';
export const FILE_SIZE_ERROR = 'Document exceeds max file size';

// client errors
const baseDisableMessage = 'This form cannot be faxed from admin because';

export const defaultServerErrorDisplayMessage =
  'There was an error sending the fax. Please reach out in the #borrowersuccess_product channel.';

export const pageLimitErrorMessage = `${baseDisableMessage} it has too many pages.`;
export const fileSizeErrorMessage = `${baseDisableMessage} it exceeds the max file size.`;

export const getFormFaxErrorMessage = (
  formType,
  formIsConsolidation,
  formServicersToSendIDR,
  formStatus,
) => {
  if (formStatus !== FormStatus.SIGNED) {
    return 'Form is not signed';
  }

  if (isIdr(formType)) {
    if (formIsConsolidation) {
      return `${baseDisableMessage} it requires consolidation.`;
    }
    if (isEmpty(formServicersToSendIDR)) {
      return `${baseDisableMessage} no servicers present.`;
    }
    if (formServicersToSendIDR.length > 1) {
      return `${baseDisableMessage} it must be sent to multiple servicers.`;
    }
    if (!supportedServicerCompanyNames.includes(formServicersToSendIDR[0])) {
      return `${baseDisableMessage} the servicer, ${formServicersToSendIDR[0]}, is not supported.`;
    }

    return null;
  }

  if (isPslf(formType)) {
    return null;
  }

  return `${baseDisableMessage} it is not a supported form type.`;
};

export const getFaxSubmissionStatus = faxSubmission => {
  if (faxSubmission) {
    if (faxSubmission.status === faxSubmissionStatus.SUCCESS) {
      return {
        type: 'success',
        alertText: 'Fax sent successfully',
      };
    }
    if (faxSubmission.status === faxSubmissionStatus.PENDING) {
      return {
        type: 'info',
        alertText: 'Fax send pending',
      };
    }
    if (faxSubmission.status === faxSubmissionStatus.ERROR) {
      return {
        type: 'warning',
        alertText: 'Fax failed, please try again',
      };
    }
  }

  return null;
};
