import { useQuery } from 'react-query';

import { api } from 'services/api';

// API request functions
const fetchStatuses = async () => {
  const res = await api.get('/statuses');

  return res.data.data;
};

const fetchAdminUsers = async () => {
  const res = await api.get('/admin-users');
  return res.data;
};

const fetchPartners = async () => {
  const res = await api.get('/partners');
  return res.data;
};

// Hooks for getting server state
export const useStatuses = () => {
  const { data: statuses } = useQuery({
    queryKey: ['statuses'],
    queryFn: fetchStatuses,
    refetchOnWindowFocus: false,
  });
  return statuses;
};

export const useAdminUsers = () => {
  const { data: owners } = useQuery({
    queryKey: ['admin_users'],
    queryFn: fetchAdminUsers,
    refetchOnWindowFocus: false,
  });
  return owners;
};

export const usePartners = () => {
  const { data: partners } = useQuery({
    queryKey: ['partners'],
    queryFn: fetchPartners,
    refetchOnWindowFocus: false,
  });
  return partners;
};
