import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AppRoutes from 'AppRoutes';
import Error from 'components/errors/Error';
import { ReactQueryClientProvider } from 'state/ReactQueryConfig';
import theme from 'styles/theme';

const App = () => {
  return (
    <ErrorBoundary fallback={Error} showDialog>
      <ThemeProvider theme={theme}>
        <Router>
          <ReactQueryClientProvider>
            <AppRoutes />
            {process.env.NODE_ENV === 'development' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </ReactQueryClientProvider>
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
