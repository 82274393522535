/* eslint-disable react/display-name */
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Cell, Header, TooltipFloater, Text } from '@summer/ui-components';

import { Box } from 'components/box/Box';
import NoDataToDisplay from 'components/placeholders/NoDataToDisplay';
import CardTable from 'components/table/SortableCardTable';
import { getIsSplittable } from 'utils/general';

const BorrowerRecommendPlans = ({ recommendedPlans }) => {
  const isBlocked = recommendedPlans?.some(plan => plan.blockedReason);
  const columns = useMemo(() => {
    return [
      {
        Header: 'Plan Type',
        accessor: 'planType',
        Cell: ({ cell }) => (
          <TooltipFloater
            placement="bottom"
            content={() => <Text small>{cell.value}</Text>}
            width={200}
            style={getIsSplittable(cell.value) ? { display: 'block' } : null}
          >
            <Cell type="string" value={cell.value} useCustomTooltip={true} />
          </TooltipFloater>
        ),
      },
      {
        Header: 'Current Plan',
        accessor: 'isCurrent',
      },
      { Header: 'Selected Plan', accessor: 'isSelected' },
      { Header: 'First Payment', accessor: 'firstPayment' },
      { Header: 'Last Payment', accessor: 'lastPayment' },
      {
        Header: 'Total Payment',
        accessor: 'totalPayment',
      },
      {
        Header: 'Amount Forgiven',
        accessor: 'amountForgiven',
      },
      {
        Header: 'No. of Payments',
        accessor: 'noOfPayments',
      },
      {
        Header: 'Consolidation?',
        accessor: 'consolidation',
      },
      {
        Header: 'With PSLF?',
        accessor: 'withPSLF',
      },
      {
        Header: 'App Type',
        accessor: 'appType',
      },

      {
        Header: 'Recommendation Reasons',
        accessor: 'recommendationReasons',
        Cell: ({ cell }) => (
          <TooltipFloater
            placement="bottom"
            content={() => <Text small>{cell.value}</Text>}
            width={200}
            style={{ display: 'block' }}
          >
            <Cell
              type="string"
              value={cell.value}
              size="small"
              useCustomTooltip={true}
            />
          </TooltipFloater>
        ),
      },
    ];
  }, []);
  const columnsBlocked = useMemo(() => {
    return [
      { Header: 'Blocked Reason', accessor: 'blockedReason' },
      { Header: 'Path Id', accessor: 'pathId' },
      { Header: 'Batch Id', accessor: 'batchId' },
    ];
  }, []);
  return (
    <>
      <Box mt={40}>
        <Header size="large" weight="bold">
          Plans Recommended (RYP Info)
        </Header>
        {isEmpty(recommendedPlans) ? (
          <Container mt={24}>
            <NoDataToDisplay />
          </Container>
        ) : (
          <TableContainer borderColor="blueGrey" mt={24}>
            <CardTable
              columns={isBlocked ? columnsBlocked : columns}
              data={recommendedPlans}
              align="left"
              colDefaultWidth={80}
              limitColWidth={true}
              style={{
                left: 0,
                overflowX: 'visible',
                overflowY: 'auto',
                flex: 0,
              }}
              virtualizedOptions={{
                height: 94 * recommendedPlans.length + 1,
              }}
              getCustomRowProps={props => {
                // combine row props With our custom row props
                const style = {
                  ...props.style,
                  left: 0,
                  width: '100%',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                };
                return {
                  ...props,
                  style,
                };
              }}
            />
          </TableContainer>
        )}
      </Box>
    </>
  );
};

const Container = styled(Box)`
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const TableContainer = styled(Box)``;

export default BorrowerRecommendPlans;
